import React, {useState} from "react";
import {Container} from "react-bootstrap";

import {BasePage} from "pages/base-page";
import {PagedView} from "components/paged-view";
import {MetaInfo} from "components/meta-info";
import {synths} from "content/synths";
import {ContactForm} from "./contact-form";
import {ContactSending} from "./contact-sending";
import {ContactSuccess} from "./contact-success";

export enum ContactStep {
    form,
    sending,
    success,
}

export const Contact = () => {
    const [contactStep, setContactStep] = useState(ContactStep.form);

    return <BasePage>
        <MetaInfo
            title="Adam Synths - Contact Us - Handcrafted in Valencia, Spain"
            description="Adam Synths - Contact Us - Handcrafted in Valencia, Spain"
            image={synths[0].bigImage}
            imageW={1920}
            imageH={1080}
            path="/contact"
        />
        <Container>
            <PagedView pageNumber={contactStep} children={[
                <ContactForm key="contact-form" setOrderStep={setContactStep}/>,
                <ContactSending key="contact-sending" setContactStep={setContactStep}/>,
                <ContactSuccess key="contact-success" setContactStep={setContactStep}/>,
            ]}/>
        </Container>
    </BasePage>
}
