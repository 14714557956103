import React, {useLayoutEffect, useState} from "react";
import {Container} from "react-bootstrap";

import {BasePage} from "pages/base-page";
import {PagedView} from "components/paged-view";
import {synths} from "content/synths";
import {MetaInfo} from "components/meta-info";

import {OrderForm} from "./order-form";
import {OrderSummary} from "./order-summary";
import {OrderSending} from "./order-sending";
import {OrderSuccess} from "./order-success";

export enum OrderStep {
    form,
    summary,
    sending,
    success,
}

export const Order = () => {
    const [orderStep, setOrderStep] = useState(OrderStep.form);

    useLayoutEffect(() => {
        // @ts-ignore
        document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
    }, [orderStep]);

    return <BasePage>
        <MetaInfo
            title="Adam Synths - Order - Handcrafted in Valencia, Spain"
            description="Adam Synths - Analog Modules - Handcrafted in Valencia, Spain"
            image={synths[0].bigImage}
            imageW={1920}
            imageH={1080}
            path="/order"
        />
        <Container>
            <PagedView pageNumber={orderStep} children={[
                <OrderForm key="order-form" setOrderStep={setOrderStep}/>,
                <OrderSummary key="order-summary" setOrderStep={setOrderStep}/>,
                <OrderSending key="order-sending" setOrderStep={setOrderStep}/>,
                <OrderSuccess key="order-success" setOrderStep={setOrderStep}/>,
            ]}/>
        </Container>
    </BasePage>
}
