import prima1 from "images/prima/prima1.jpg";
import prima2 from "images/prima/prima2.jpg";
import prima3 from "images/prima/prima3.jpg";
import prima4 from "images/prima/prima4.jpg";
import prima5 from "images/prima/prima5.jpg";

import prima1Big from "images/prima/prima1-big.jpg";
import prima2Big from "images/prima/prima2-big.jpg";
import prima3Big from "images/prima/prima3-big.jpg";
import prima4Big from "images/prima/prima4-big.jpg";
import prima5Big from "images/prima/prima5-big.jpg";

export type Description = {
    title: string;
    description: string;
}

export type SynthInfo = {
    title: string;
    motto: string;
    model: string
    modelNr: string
    description: Description[];
    images: string[];
    bigImages: string[];
    bigImage: string;
    price: number;
}


export const synths: Array<SynthInfo> = [
    {
        title: "Meet the Prima!",
        motto: "Pure analog goodness as it's best!\nSeven of our high quality modules.",
        model: "Prima",
        modelNr: "PRIMA",
        description: [
            {
                title: "Modular System",
                description:
                    "Introducing Prima, our meticulously engineered pre-assembled modular system! With our proprietary bus, it comes pre-configured and primed for immediate use straight out of the box. Each front-board I/O has a counterpart on the back panel, and in Prima many are already interconnected in a sensible configuration."
            },
            {
                title: "Normalized IO",
                description: "These connections are made in a [normalized manner](https://en.wikipedia.org/wiki/Patch_panel), but if you simply plug a patch cable into the corresponding front-panel IO, you can override these connections as needed.",
            },
            {
                title: "High Quality Components",
                description: "We prioritize the use of top-notch components in crafting our devices, recognizing that quality materials are fundamental to exceptional performance. That's why Prima is crafted from carefully selected parts that meet the highest standards in both audio circuits and controls.",
            },
            {
                title: "Made In The EU",
                description: "Prima's circuits, designed in Hungary, showcase cutting-edge engineering. Assembled in Spain with precision, each component of the Prima is brought together to form a harmonious whole. Holding the Prima, you experience European ingenuity, a masterpiece of cross-border collaboration."
            },
            {
                title: "Built From Scratch",
                description:
                    "Prima's circuits originated on a blank canvas to become a thoroughly modern instrument constructed entirely from circuits conceived and designed by Adam Synths. Holding the Prima, you're not grasping a reinterpretation or duplicate, but an OG."
            }
        ],
        bigImage: prima1,
        images: [prima2, prima3, prima4, prima5],
        bigImages: [prima1Big, prima2Big, prima3Big, prima4Big, prima5Big],
        price: 2100
    }
];
