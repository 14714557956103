import prima1Big from "./images/prima/prima1-big.jpg";
import prima2Big from "./images/prima/prima2-big.jpg";
import prima3Big from "./images/prima/prima3-big.jpg";
import prima4Big from "./images/prima/prima4-big.jpg";
import prima5Big from "./images/prima/prima5-big.jpg";

export const primaImages = [
    prima1Big,
    prima2Big,
    prima3Big,
    prima4Big,
    prima5Big,
];

export const maxQuantity = 9;
