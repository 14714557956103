import React from "react";
import {Col, Row} from "react-bootstrap";

import {SynthInfo, synths} from "content/synths";
import {SynthOrderRow} from "./synth-order-row";
import {ModuleOrderRow} from "./module-order-row";
import {ModuleInfo, modules} from "content/modules";
import {useOrder} from "state/order";
import {formatPrice} from "utils/format-price";
import {TotalWrapper} from "../styles";
import {isEU} from "content/countries";
import {SlidingNumber} from "components/slide-up-number";
import {useDelay} from "utils/animation-delay";
import {getVat, totalCalc} from "utils/total-calc";
import {Label} from "pages/styles";

type Props = {
    readOnly?: boolean;
}

export const ItemList = ({ readOnly = false}: Props) => {
    const {orderState} = useOrder();
    const {nextDelay: nd} = useDelay({start: 50});

    const moduleTotal = totalCalc(
        orderState.modules,
        (model) => modules.find(m => m.model === model)?.price
    );

    const synthTotal = totalCalc(
        orderState.synths,
        (model) => synths.find(m => m.model === model)?.price
    );

    const total = moduleTotal + synthTotal;
    const vat = getVat(orderState);
    const shipping = total > 0 ? (isEU(orderState.country) ? 50 : 120) : 0;
    const filter = readOnly ? (({model}: SynthInfo | ModuleInfo) => (orderState.synths[model] > 0 || orderState.modules[model] > 0)) : ((_: SynthInfo | ModuleInfo) => true);
    return <>
        <Row className="mb-4 mt-4">
            {synths.filter(filter).map(s => <SynthOrderRow key={s.model} synth={s} readOnly={readOnly}/>)}
            {modules.filter(filter).map(s => <ModuleOrderRow key={s.model} synth={s} readOnly={readOnly}/>)}
        </Row>

        <Row className="mb-0">
            <Col md={12} className="flex-row d-flex align-items-center justify-content-between">
                <Label>Subtotal:</Label>
                <SlidingNumber delay={nd()} value={total} formatter={formatPrice} $justifyContent="end"/>
            </Col>
        </Row>

        <Row className="mb-0">
            <Col md={12} className="flex-row d-flex align-items-center justify-content-between">
                <Label>VAT ({vat * 100}%):</Label>
                <SlidingNumber delay={nd()} value={vat * total} formatter={formatPrice} $justifyContent="end"/>
            </Col>
        </Row>

        <Row className="mb-0">
            <Col md={12} className="flex-row d-flex align-items-center justify-content-between">
                <Label>Shipping:</Label>
                <SlidingNumber delay={0} value={shipping} formatter={formatPrice} $justifyContent="end"/>
            </Col>
        </Row>

        <TotalWrapper>
            <Row className="mt-2">
                <Col md={12} className="flex-row d-flex align-items-center justify-content-between">
                    <b>Total:</b>
                    <b>
                        <SlidingNumber delay={nd()}
                                       value={total + vat * total + shipping}
                                       formatter={formatPrice}
                                       $justifyContent="end"/>
                    </b>
                </Col>
            </Row>
        </TotalWrapper>
    </>;
}

