import React from "react";
import {Spinner} from "react-bootstrap";

import {AdminBase} from "./admin-base";
import {Center} from "components/styles";

export const AdminProgress = () =>
    <AdminBase>
        <Center>
            <Spinner animation="border"/>
        </Center>
    </AdminBase>
