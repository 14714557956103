import * as React from 'react';
import {Html} from '@react-email/html';
import {Markdown} from "@react-email/markdown";

import {Contact} from "state/contact";

type Props = {
    contact: Contact;
}

const EmailFN = ({contact}: Props) => {
    const {
        firstName,
        lastName,
        email,
        message,
        source,
        sourceOther
    } = contact;

    return (
        <Html lang="en">
            <Markdown children={`## New Contact from ${firstName} ${lastName}`}/>
            <table>
                <tr>
                    <td><b>First Name:</b></td>
                    <td>{firstName}</td>
                </tr>
                <tr>
                    <td><b>Last Name:</b></td>
                    <td>{lastName}</td>
                </tr>
                <tr>
                    <td><b>Email:</b></td>
                    <td>{email}</td>
                </tr>
                <tr>
                    <td><b>Message:</b></td>
                    <td>{message}</td>
                </tr>
                <tr>
                    <td><b>Source:</b></td>
                    <td>{source}</td>
                </tr>
                <tr>
                    <td><b>Source other:</b></td>
                    <td>{sourceOther}</td>
                </tr>
            </table>
        </Html>
    );
};

export const ContactEmail = React.memo<Props>(EmailFN);
