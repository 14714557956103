import React, {useCallback, useEffect, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {Button, Col, Form, Nav, Row} from "react-bootstrap";
import styled from "styled-components";

import {Address, Description, MainTitle} from "components/styles";
import {ContactStep} from "./contact";
import {useDelayedFadeIn} from "utils/animation-delay";
import {updateContactAction, useContact} from "state/contact";
import {FadeIn} from "components/fade-in";
import adam from "images/adam.jpg";
import {instagramLink, soundCloudLink, youtubeLink} from "utils/links";
import wireframe from "images/wireframe.png";
import {sources} from "content/countries";

type Props = {
    setOrderStep: (orderStep: ContactStep) => void;
}

export const Content = styled.div`
  background-image: url(${wireframe});
  background-size: contain;
  background-repeat: repeat-y;
  background-position: center;
  animation-delay: 0s;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
`
export const ContactForm = ({setOrderStep}: Props) => {
    const {nextDelay} = useDelayedFadeIn({});
    const {contactState, dispatch} = useContact();
    const [validated, setValidated] = useState(false);
    const [captcha, setCaptcha] = useState("");
    const handleCaptchaChange = (value: Object | null) => setCaptcha(value as string);
    const [otherSource, setOtherSource] = useState(false);

    useEffect(() => {
        setOtherSource((contactState.source ?? "").includes("Please"));
    }, [contactState.source, setOtherSource]);


    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        dispatch(updateContactAction({
            ...contactState,
            [name]: value
        }));
    }, [contactState, dispatch]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;
        setValidated(false);
        if (form.checkValidity()) {
            setOrderStep(ContactStep.sending);
        }
        setValidated(true);
    };

    return <Content>
        <MainTitle>
            <FadeIn nextDelay={nextDelay}>
                Contact Us
            </FadeIn>
        </MainTitle>
        <Description>
            <Row>
                <Col md={6} className="mt-4">
                    <FadeIn nextDelay={nextDelay}>
                        <img src={adam} alt="Adam Miski - Founder & CEO of AdamSynths"/>
                    </FadeIn>
                </Col>
                <Col md={6} className="mt-4">
                    <FadeIn nextDelay={nextDelay}>
                        We take pride in designing our beloved modules in Hungary and crafting by hand in Spain!
                        <br/>
                        <br/>
                        We're always excited to connect with fellow enthusiasts like you and have a conversations about
                        analog synthesizers and music.
                        <br/>
                        <br/>
                        Whether you have inquiries about our current production line, dreams of a unique custom project,
                        need guidance in finding your ideal module or setup, or just say hello, please don't hesitate to
                        reach out to us.
                        <br/>
                        <br/>
                        Looking forward to hearing from you,
                        <br/>
                        Adam
                    </FadeIn>
                </Col>
            </Row>
        </Description>
        <Description className="mt-5">
            <FadeIn nextDelay={nextDelay}>
                <Address className="ms-5 me-5">
                    <b>BUMBLEBEE D.A.O.</b>
                    <br/>
                    10145, Kesklinna linnaosa
                    <br/>
                    Tornimäe tn 3// 5// 7
                    <br/>
                    Tallinn
                    <br/>
                    Harju maakond
                    <br/>
                    <br/>
                    VAT nr: EE102670545
                </Address>
            </FadeIn>
        </Description>
        <Form noValidate validated={validated} className="mt-5" onSubmit={handleSubmit}>
            <FadeIn nextDelay={nextDelay}>
                <Row>
                    <Col md={6} className="mt-4">
                        <Form.Group controlId="formFirstName">
                            <Form.Label className="required">
                                First Name
                            </Form.Label>
                            <Form.Control type="text"
                                          name="firstName"
                                          value={contactState.firstName}
                                          onChange={handleChange}
                                          required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide your first name.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6} className="mt-4">
                        <Form.Group controlId="formLastName">
                            <Form.Label className="required">
                                Last Name
                            </Form.Label>
                            <Form.Control type="text"
                                          name="lastName"
                                          value={contactState.lastName}
                                          onChange={handleChange}
                                          required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide your last name.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="mt-4">
                        <Form.Group controlId="fromSource">
                            <Form.Label>
                                How Did You Hear About Us?
                            </Form.Label>
                            <Form.Control
                                name="source"
                                as="select"
                                value={contactState.source}
                                onChange={handleChange}>
                                <option value="">Choose one...</option>
                                {sources.map(c =>
                                    <option key={c}
                                            value={c}>
                                        {c}
                                    </option>)}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    {otherSource ?
                        <Col md={6} className="mt-4">
                            <Form.Group controlId="sourceOther">
                                <Form.Label>
                                    Other
                                </Form.Label>
                                <Form.Control type="text"
                                              name="sourceOther"
                                              placeholder="If you selected 'Other', please specify."
                                              value={contactState.sourceOther}
                                              onChange={handleChange}
                                              required
                                />
                            </Form.Group>
                        </Col> : null}
                </Row>
                <Row>
                    <Col md={6} className="mt-4">
                        <Form.Group controlId="formEmail">
                            <Form.Label className="required">
                                Email
                            </Form.Label>
                            <Form.Control type="text"
                                          name="email"
                                          value={contactState.email}
                                          onChange={handleChange}
                                          required
                                          pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid email address.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6} className="mt-4">
                        <Form.Group controlId="formMessage">
                            <Form.Label>
                                Message
                            </Form.Label>
                            <Form.Control as="textarea" rows={5}
                                          name="message"
                                          value={contactState.message}
                                          onChange={handleChange}

                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mt-4 d-flex flex-row align-items-center justify-content-center">
                    <Col md={6} className="mb-4 d-flex flex-row align-items-center justify-content-center">
                        <Form.Group controlId="formCaptcha">
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY ?? ""}
                                onChange={handleCaptchaChange}
                            />
                            <Form.Control
                                type="text"
                                name="captcha"
                                value={captcha}
                                onChange={() => {
                                }}
                                required
                                className="d-none"
                            />
                            <Form.Control.Feedback type="invalid">
                                Please verify that you are not a robot.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6} className="mb-4 d-flex flex-column align-items-stretch justify-content-center">
                        <Button variant="primary"
                                size="lg"
                                type="submit">
                            <b>Send</b>
                        </Button>
                    </Col>
                </Row>
            </FadeIn>
        </Form>
        <Row className="mt-4">
            <Col md={4} className="mt-2">
                <FadeIn nextDelay={nextDelay}>
                    <Nav.Link href={instagramLink} target="_blank" className="social-logo d-flex flex-column"
                              style={nextDelay()}>
                        <p>Instagram</p>
                    </Nav.Link>
                </FadeIn>
            </Col>
            <Col md={4} className="mt-2">
                <FadeIn nextDelay={nextDelay}>
                    <Nav.Link href={youtubeLink} target="_blank" className="social-logo d-flex flex-column"
                              style={nextDelay()}>
                        <p>YouTube</p>
                    </Nav.Link>
                </FadeIn>
            </Col>
            <Col md={4} className="mt-2">
                <FadeIn nextDelay={nextDelay}>
                    <Nav.Link href={soundCloudLink} target="_blank" className="social-logo d-flex flex-column"
                              style={nextDelay()}>
                        <p>SoundCloud</p>
                    </Nav.Link>
                </FadeIn>
            </Col>
        </Row>
    </Content>
}
