import React, {useCallback, useEffect, useRef} from "react";
import {Spinner} from "react-bootstrap";
import {render} from "@react-email/render";
import styled from "styled-components";

import {useDelayedFadeIn} from "utils/animation-delay";
import {OrderStep} from "./order";
import {useOrder} from "state/order";
import {InquiryEmail} from "./inquiry-email";
import {FadeIn} from "components/fade-in";
import {baseURL} from "utils/base-url";

type Props = {
    setOrderStep: (orderStep: OrderStep) => void;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-direction: column;
  height: 85vh;
`

export const OrderSending = ({setOrderStep}: Props) => {
    const {nextDelay} = useDelayedFadeIn({});
    const {orderState} = useOrder();
    const effectRan = useRef(false);

    const postInquiry = useCallback(async () => {
        const html = render(<InquiryEmail order={orderState}/>)
        const response = await fetch(`${baseURL()}/api/inquiry`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({html, name: `${orderState.firstName} ${orderState.lastName}`}),
        });
        return response.json();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!effectRan.current) {
            postInquiry().then(() => setOrderStep(OrderStep.success));
        }

        return () => {
            effectRan.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <FadeIn nextDelay={nextDelay}>
        <Container>
            <Spinner animation="border"/>
        </Container>
    </FadeIn>;
}
