import React from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import {MainTitle} from "components/styles";
import {OrderStep} from "./order";
import {ItemList} from "./form/item-list";
import {UserDataSummary} from "./form/user-data-summary";
import styled from "styled-components";

type Props = {
    setOrderStep: (orderStep: OrderStep) => void;
}

const Container = styled.div`
`;

export const OrderSummary = ({setOrderStep}: Props) =>
    <Container>
        <Form noValidate validated={true} className="mt-4">
            <MainTitle>Order Summary</MainTitle>
            <ItemList readOnly/>
            <h4 className="mt-5">Your Data</h4>
            <UserDataSummary/>
            <Row className="mt-5 d-flex flex-row align-items-center justify-content-center">
                <Col md={6}
                     className="mb-4 d-flex flex-column align-items-stretch justify-content-center">
                    <Button variant="outline-primary" size="lg" onClick={() => setOrderStep(OrderStep.form)}>
                        <b>Go back</b>
                    </Button>
                </Col>
                <Col md={6}
                     className="mb-4 d-flex flex-column align-items-stretch justify-content-center">
                    <Button variant="primary" size="lg" onClick={() => setOrderStep(OrderStep.sending)}>
                        <b>Place your Order</b>
                    </Button>
                </Col>
            </Row>
        </Form>
    </Container>
