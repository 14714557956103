import "yet-another-react-lightbox/styles.css";
import React from "react";

import {synths} from "content/synths";
import {Synth} from "../synth/synth";
import {MetaInfo} from "components/meta-info";

export const Home = () =>
    <>
        <MetaInfo
            title="Adam Synths - Prima - Analog Modular Synthesizer - Handcrafted in Valencia, Spain"
            description={synths[0].description[0].description}
            image={synths[0].bigImage}
            imageW={1920}
            imageH={1080}
            path="/"
        />
        {synths.map((synth, i) => <Synth key={i} synth={synth}/>)}
        {/* <div onClick={purgeOrder} style={{opacity: 0.2}}>clear</div>*/}
    </>
