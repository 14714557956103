import React, {useCallback, useEffect, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";

import {countries, Country, sources, State} from "content/countries";
import {updateOrderAction, useOrder} from "state/order";

export const UserData = () => {
    const {orderState, dispatch} = useOrder();
    const [otherSource, setOtherSource] = useState(false);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        dispatch(updateOrderAction({
            ...orderState,
            [name]: value
        }));
    }, [orderState, dispatch]);

    useEffect(() => {
        setOtherSource((orderState.source ?? "").includes("Please"));
    }, [orderState.source, setOtherSource]);

    const handleRadioChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const {name} = e.target;
        dispatch(updateOrderAction({
            ...orderState,
            "paymentOption": name
        }));
    }, [orderState, dispatch]);

    const states = countries.find(c => c.code2 === orderState.country)?.states || [];

    return <>
        <Row>
            <Col md={6} className="mt-4">
                <Form.Group controlId="formFirstName">
                    <Form.Label className="required">
                        First Name
                    </Form.Label>
                    <Form.Control type="text"
                                  name="firstName"
                                  value={orderState.firstName}
                                  onChange={handleChange}
                                  required
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide your first name.
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col md={6} className="mt-4">
                <Form.Group controlId="formLastName">
                    <Form.Label className="required">
                        Last Name
                    </Form.Label>
                    <Form.Control type="text"
                                  name="lastName"
                                  value={orderState.lastName}
                                  onChange={handleChange}
                                  required
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide your last name.
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col md={6} className="mt-4">
                <Form.Group controlId="formEmail">
                    <Form.Label className="required">
                        Email
                    </Form.Label>
                    <Form.Control type="text"
                                  name="email"
                                  value={orderState.email}
                                  onChange={handleChange}
                                  required
                                  pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid email address.
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col md={6} className="mt-4">
                <Form.Group controlId="formCountry">
                    <Form.Label className="required">
                        Country
                    </Form.Label>
                    <Form.Control
                        name="country"
                        as="select"
                        value={orderState.country}
                        onChange={handleChange}>
                        <option value="">Choose one...</option>
                        {countries.map((c: Country) =>
                            <option key={c.code2}
                                    value={c.code2}>
                                {c.name}
                            </option>)}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        Please provide your address.
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
        {states.length > 0 ?
            <Form.Group className="mt-4" controlId="formState">
                <Form.Label className="required">
                    State/Region
                </Form.Label>
                <Form.Control
                    name="state"
                    as="select"
                    disabled={states.length === 0}
                    value={orderState.state}
                    required
                    onChange={handleChange}>
                    <option value="">Choose one...</option>
                    {states.map((s: State) =>
                        <option key={s.code}
                                value={s.code}>
                            {s.name}
                        </option>)}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please provide your address.
                </Form.Control.Feedback>
            </Form.Group> : null}
        <Form.Group className="mt-4" controlId="formAddressLine1">
            <Form.Label className="required">
                Address Line 1
            </Form.Label>
            <Form.Control type="text"
                          name="addressLine1"
                          value={orderState.addressLine1}
                          onChange={handleChange}
                          required
            />
            <Form.Control.Feedback type="invalid">
                Please provide your address details.
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mt-4" controlId="formAddressLine2">
            <Form.Label>
                Address Line 2
            </Form.Label>
            <Form.Control type="text"
                          name="addressLine2"
                          value={orderState.addressLine2}
                          onChange={handleChange}
            />
        </Form.Group>
        <Row>
            <Col md={6} className="mt-4">
                <Form.Group controlId="formPostalCode">
                    <Form.Label className="required">
                        Postal Code
                    </Form.Label>
                    <Form.Control type="text"
                                  name="postalCode"
                                  value={orderState.postalCode}
                                  onChange={handleChange}
                                  required
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide your postal code.
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col md={6} className="mt-4">
                <Form.Group controlId="formCity">
                    <Form.Label className="required">
                        City
                    </Form.Label>
                    <Form.Control type="text"
                                  name="city"
                                  value={orderState.city}
                                  onChange={handleChange}
                                  required
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide your city.
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col md={6} className="mt-4">
                <Form.Group controlId="fromSource">
                    <Form.Label>
                        How Did You Hear About Us?
                    </Form.Label>
                    <Form.Control
                        name="source"
                        as="select"
                        value={orderState.source}
                        onChange={handleChange}>
                        <option value="">Choose one...</option>
                        {sources.map(c =>
                            <option key={c}
                                    value={c}>
                                {c}
                            </option>)}
                    </Form.Control>
                </Form.Group>
            </Col>
            {otherSource ?
                <Col md={6} className="mt-4">
                    <Form.Group controlId="sourceOther">
                        <Form.Label>
                            Other
                        </Form.Label>
                        <Form.Control type="text"
                                      name="sourceOther"
                                      placeholder="If you selected 'Other', please specify."
                                      value={orderState.sourceOther}
                                      onChange={handleChange}
                                      required
                        />
                    </Form.Group>
                </Col> : null}
        </Row>
        <Form.Group className="mt-4" controlId="formPaymentOption">
            <Form.Label className="required">Payment Option</Form.Label>
            {['PayPal', 'Bank transfer (IBAN, SEPA)'].map((option, index) => (
                <div key={index} className="mb-3">
                    <Form.Check
                        type="radio"
                        id={`radio-${index}`}
                        label={option}
                        name={option}
                        required={orderState.paymentOption === ""}
                        checked={(option === orderState.paymentOption)}
                        onChange={handleRadioChange}
                    />
                </div>
            ))}
        </Form.Group>
        <Form.Group className="mt-4" controlId="formMessage">
            <Form.Label>
                Message
            </Form.Label>
            <Form.Control as="textarea" rows={5}
                          name="message"
                          value={orderState.message}
                          onChange={handleChange}

            />
        </Form.Group>

    </>;
}

