import React, {createContext, useState, useContext, useEffect, useCallback} from 'react';

export enum UnitType {
    metric = "metric",
    imperial = "imperial"
}

type ContextProp = {
    unit: UnitType;
    toggleUnit:() => void;
}

const UnitContext = createContext<ContextProp>({unit: UnitType.metric, toggleUnit: () => {}});
const key = 'unit';

export const useUnit = () => useContext(UnitContext);

type Props = {
    children: React.ReactNode;
}

export const UnitProvider = ({children}: Props) => {
    const [unit, setUnit] = useState<UnitType>(() => {
        const storedUnit = localStorage.getItem(key);
        return storedUnit as UnitType ?? UnitType.metric;
    });

    useEffect(() => {
        localStorage.setItem(key, unit);
    }, [unit]);

    const toggleUnit = useCallback(() => {
        setUnit(unit === UnitType.metric ? UnitType.imperial: UnitType.metric);
    }, [unit]);

    return (
        <UnitContext.Provider value={{unit, toggleUnit}}>
            {children}
        </UnitContext.Provider>
    );
};
