import React, {Component, ComponentType, ErrorInfo, ReactNode} from 'react';

type ErrorBoundaryProps = {
    children: ReactNode;
}

type ErrorBoundaryState = {
    hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError = (_: Error): ErrorBoundaryState => ({hasError: true});

    componentDidCatch = (error: Error, errorInfo: ErrorInfo) => console.log(error, errorInfo);

    render() {
        if (this.state.hasError) {
            console.log('------>youtube failed: ');
            return null;
        }

        return this.props.children;
    }
}


export const withErrorHandling = <P extends Object>(WrappedComponent: ComponentType<P>) => class extends Component<P> {
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error("error handler:", error);
    }

    render() {
        try {
            return <WrappedComponent {...this.props as P} />;
        } catch (error) {
            return null;
        }
    }
};

