import React from "react";
import {Helmet} from "react-helmet";

type Props = {
    title: string;
    description: string;
    path: string;
    image: string;
    imageW: number;
    imageH: number;
}
const baseUrl = "https://adamsynths.com"

export const MetaInfo = React.memo<Props>(({title, description, path, image, imageW, imageH}) =>
    <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={`${baseUrl}${path}`}/>
        <link rel="image_src" href={`${baseUrl}${image}`}/>
        <link rel="icon" type="image/x-icon"
              href="/favicon.ico"/>
        <meta property="og:site_name" content="Adam Synths"/>
        <meta property="og:title"
              content={title}/>
        <meta property="og:url" content={`${baseUrl}${path}`}/>
        <meta property="og:type" content="website"/>
        <meta property="og:image"
              content={`${baseUrl}${image}`}/>
        <meta property="og:image:width" content={imageW + ""}/>
        <meta property="og:image:height" content={imageH + ""}/>
        <meta itemProp="name"
              content={title}/>
        <meta itemProp="url" content={`${baseUrl}${path}`}/>
        <meta itemProp="thumbnailUrl"
              content={`${baseUrl}${image}`}/>
        <meta itemProp="image"
              content={`${baseUrl}${image}`}/>
        <meta name="twitter:title"
              content={description}/>
        <meta name="twitter:image"
              content={`${baseUrl}${image}`}/>
        <meta name="twitter:url" content={`${baseUrl}${path}`}/>
        <meta name="twitter:card" content="summary"/>
        <meta name="description" content={description}/>
    </Helmet>);


