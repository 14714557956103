import {useEffect, useState} from "react";
import styled from "styled-components";

type Props = {
    images: Array<string>;
    interval?: number;
}

const CrossFadeContainer = styled.div`
  width: 100%;
  aspect-ratio: 1024 / 724;
  overflow: hidden;
`

const CrossFadeImage = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 1024 / 724;
  background-size: cover;
  background-position: center;
  opacity: 1;
  transition: opacity 6s ease-in-out;
`

export const CrossFadeImages = ({images, interval = 12000}: Props) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, interval);

        return () => clearInterval(timer);
    }, [images.length, interval]);

    return <CrossFadeContainer>
        {images.map((image, index) => (
            <CrossFadeImage
                key={index}
                style={{backgroundImage: `url(${image})`, opacity: index === currentImageIndex ? 1 : 0}}
            />
        ))}
    </CrossFadeContainer>;
};
