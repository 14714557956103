import React, {useCallback} from "react";
import {Button, Col} from "react-bootstrap";

import {ModuleInfo} from "content/modules";
import {useDelayedFadeIn} from "utils/animation-delay";
import {FadeIn} from "components/fade-in";
import {GetVisible} from "components/get-visible";
import {useNavigate} from "react-router-dom";
import {formatPrice} from "../../utils/format-price";
import {changeQuantityAction, useOrder} from "../../state/order";

type Props = {
    module: ModuleInfo;
}

export const ModuleOrder = ({module: {model, title, price}}: Props) => {
    const {nextDelay} = useDelayedFadeIn({});
    const {dispatch} = useOrder();
    const navigate = useNavigate();
    const order = useCallback(() => {
        dispatch(changeQuantityAction(model, true, 1));
        navigate(`/order`);
    }, [dispatch, navigate, model]);

    return <GetVisible
        fader={nextDelay}
        builder={(visible, immediately, nd) => !visible ? <div/> :
            <FadeIn nextDelay={nd}>
                <h4>Order {title}</h4>
                <h5><b>{formatPrice(price)}</b></h5><h6> + VAT + Shipping (EU € 50.00, World €
                120.00)</h6>
                <Col md={12} className="mt-4 d-flex flex-column align-items-stretch justify-content-center">
                    <Button variant="primary"
                            size="lg"
                            onClick={order}>
                        <b>Order</b>
                    </Button>
                </Col>
            </FadeIn>}/>;
}

