import React from 'react';
import {Link} from "react-router-dom";

import {AlternateRow} from "components/alternate-row";
import {ModuleInfo} from "content/modules";
import {Badges} from "components/badges";
import {useDelayedFadeIn} from "utils/animation-delay";
import {FadeIn} from "components/fade-in";
import {CrossFadeImages} from "components/crossfade-images";
import {GetVisible} from "components/get-visible";
import {MarkdownRenderer} from "../../utils/markdown-renderer";

type Props = {
    info: ModuleInfo,
    fadeDelay: number,
    isRight?: boolean,
};

const StaticModuleRow = ({info: {title, model, description, images}}: Props) => {
    const content1 = <img src={images[0]} alt={title}/>;

    const content2 = <>
        <h4>{title}</h4>
        <p>{description[0]}</p>
    </>;

    return <Link to={`/modules/${model}`}>
        <AlternateRow content1={content1} content2={content2} isRight={false}/>
    </Link>;
};

const FadedModuleRow = ({
                            info: {title, model, description, images, badges},
                            fadeDelay,
                            isRight = false,
                        }: Props) => {
    const {nextDelay} = useDelayedFadeIn({start: fadeDelay});

    const content1 = <FadeIn nextDelay={nextDelay}>
        <CrossFadeImages images={images}/>
    </FadeIn>;

    const content2 = <>
        <FadeIn nextDelay={nextDelay}>
            <h4>{title}</h4>
        </FadeIn>
        <FadeIn nextDelay={nextDelay}>
            <MarkdownRenderer markdown={description[0]} />
        </FadeIn>
        <div className="d-flex justify-content-between align-items-center align-self-center">
            <Badges nextDelay={nextDelay} badges={badges}/>
            <div>
                <FadeIn nextDelay={nextDelay}>
                    <b>More &nbsp; ➤</b>
                </FadeIn>
            </div>
        </div>

    </>;

    return <Link to={`/modules/${model}`}>
        <AlternateRow content1={content1} content2={content2} isRight={isRight}/>
    </Link>;
};

export const ModuleRow = ({info, fadeDelay, isRight}: Props) => <GetVisible key={info.model}
                                                                            builder={(visible, immediately) => !visible ?
                                                                                <StaticModuleRow info={info}
                                                                                                 fadeDelay={fadeDelay}/> :
                                                                                <FadedModuleRow info={info}
                                                                                                fadeDelay={immediately ? fadeDelay : 0}
                                                                                                isRight={isRight}/>
                                                                            }/>;
