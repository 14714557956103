import styled from "styled-components";
const size = 64;

export const ThumbWrapper = styled.div<{$enabled: boolean}>`
  height: ${size}px;
  width: ${size}px;
  opacity: ${({$enabled}) => $enabled ? 1 : 0.5};
  user-select: none;
`;

export const ItemWrapper = styled.div`
  border-bottom: 1px solid rgba(255,255,255,0.1);
`;

export const TotalWrapper = styled.div`
  border-top: 1px solid rgba(255,255,255,0.5);
`;

export const Price = styled.div<{$enabled: boolean}>`
  opacity: ${({$enabled}) => $enabled ? 1 : 0.5};
`;

export const Count = styled.div<{$enabled: boolean}>`
  opacity: ${({$enabled}) => $enabled ? 1 : 0.5};
  margin: 0 8px;
  width: 12px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;
