import React, {useCallback} from "react";

import {SynthInfo,} from "content/synths";
import {changeQuantityAction, useOrder} from "state/order";
import {OrderItem} from "./order-item";
import {maxQuantity} from "../../../constants";

type Props = {
    synth: SynthInfo;
    readOnly: boolean;
}

export const SynthOrderRow = ({synth: {title, model, images, price}, readOnly}: Props) => {
    const {orderState, dispatch} = useOrder();
    const quantity = orderState.synths[model];
    const minusEnabled = quantity > 0;
    const plusEnabled = quantity < maxQuantity;

    const increase = useCallback(() =>
        dispatch(changeQuantityAction(model, false, +1)), [model, dispatch]);

    const decrease = useCallback(() =>
        dispatch(changeQuantityAction(model, false, -1)), [model, dispatch]);

    return <OrderItem
        minusEnabled={minusEnabled}
        plusEnabled={plusEnabled}
        image={images[0]}
        title={title}
        model={model}
        price={price}
        decrease={decrease}
        quantity={quantity}
        increase={increase}
        isSynth={true}
        readOnly={readOnly}
    />;
};


