export type QuantityMap = { [key: string]: number };

export interface Entity {
    id?: string;
    [key: string]: any;
}

export interface User extends Entity {
    id?: string;
    firstName: string;
    lastName: string;
    email: string;
    country: string;
    state: string;
    addressLine1: string;
    addressLine2: string;
    postalCode: string;
    city: string;
}

export enum ShippingStatusEnum {
    created,
    assembled,
    packed,
    dispatched,
    received,
}

export interface ShippingStatus {
    timestamp: number;
    status: ShippingStatusEnum;
}

export interface Message {
    email: string;
    timestamp: number;
    email_id: string;
    content: string;
}

type BaseOrder = {
    paymentOption: string;
    message: string;
    synths: QuantityMap;
    modules: QuantityMap;
    source?: string;
    sourceOther?: string;
};

export interface Order extends Entity  {
    id?: string;
    created: number;
    buyer: User;
    paymentOption: string;
    message: string;
    synths: QuantityMap;
    modules: QuantityMap;
    source?: string;
    sourceOther?: string;
    moduleIds: string[]; // id-s of actual module instances
    synthIds: string[]; // id-s of actual synth instances
    shippingHistory: ShippingStatus[];
    messages: Message[];
}

export const EmptyUser: User = {
    id: undefined,
    firstName: "",
    lastName:"",
    email: "",
    country: "",
    state: "",
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    city: "",
}

export type UserOrder = User & BaseOrder;

