import {UnitType} from "state/unit";

export const convertUnit = (input: string, unit: UnitType): string => input.split(" ").map(convertToken(unit)).join(" ")

const convertToken = (unit: UnitType) => (input: string) => {
    if (unit === UnitType.metric) {
        return input;
    }

    if (!input.includes("mm")) {
        return input;
    }

    const source = stringToInt(input);
    const dest = mmToInches(source);
    return input
        .replace(`${source.toFixed(1)}mm`, dest)
        .replace(`${source.toFixed(0)}mm`, dest);
}

function stringToInt(value: string): number {
    let intValue = 0;
    let hasDecimal = false;
    let decimalMultiplier = 1;

    for (let i = 0; i < value.length; i++) {
        const charCode = value.charCodeAt(i);

        if (charCode === 46 && !hasDecimal) { // Check for decimal point
            hasDecimal = true;
            continue;
        }

        if (charCode >= 48 && charCode <= 57) { // Check if the character is a digit (ASCII codes 48 to 57)
            intValue = intValue * 10 + (charCode - 48);
            if (hasDecimal) {
                decimalMultiplier *= 0.1; // If decimal, reduce the decimalMultiplier
            }
        }
    }

    return intValue * decimalMultiplier;
}

const mmToInches = (mm: number): string => `${(mm / 25.4).toFixed(5)}''`;
