export const privacyPolicy = ["The protection of your data is very important to us. To visit our online store, you do not have to enter any data. We only store anonymized access data without personal references, such as the name of your internet service provider, the page from which you visit us, or the name of the requested file. Cookies may be used to collect data, but these also collect and store data exclusively in anonymous or pseudonymous form and do not allow any conclusions to be drawn about your person.",
    "We only collect personal data if you voluntarily provide it to us in the course of the ordering process, when opening a customer account or when registering for our newsletter. We use the collected data exclusively for the execution of the contract. After complete processing of the contract, your data will be blocked and deleted after the expiration of the tax and commercial regulations, unless you have expressly agreed to further use of data. If you register for our monthly newsletter with your email address, we will use your email address for our own advertising purposes until you unsubscribe from the newsletter.",
    "<b>Data security<b/>",
    "Your personal data is safe with us! They are encrypted by SSL and transmitted to us. Our websites are protected by technical measures against damage, destruction, unauthorized access.",
    "<b>Right of information & revocation<b/>",
    "You will receive information about your data stored with us free of charge at any time without giving reasons. You can have your data collected by us blocked, corrected, or deleted at any time. You can revoke your consent to the collection and use of your data at any time without giving reasons. To do so, please contact: info@adamsynths.com.",
    "1. For the execution of sales contracts (initiation, conclusion, processing, warranties, and reversal), for our services, technical administration, our own marketing purposes and to ensure the existing correspondence between you and us, we collect personal data for the ordering process as well as for opening a customer account, which include in detail: Name, contact data, payment data, eMail, telephone number. A transfer of their data can be made in principle to several companies, as far as this is necessary for the processing of our contractual relationship, in particular in the following cases:",
    "a) A transfer always takes place for the purpose of delivery to the shipping company commissioned by us for this purpose. The transfer includes only such data that are necessary for the delivery of the goods.",
    "b) If payment services are used for the payment process (e.g. Paypal, Visa, Mastercard...), your data will be passed on to the payment intermediaries used. The same applies with regard to the credit institutions commissioned with the payment.",
    "c) In the event of payment problems, a claim may be assigned to a collection agency. In addition, information may be sent to credit agencies. The disclosure of the personal data required for this purpose shall be governed by the explanations on Bonity-Checking.",
    "The data passed on may be used by the service providers exclusively for the purpose of passing it on.",
    "2. Beyond this, your personal data will not be passed on to third parties unless we are legally obliged to do so or you have given us your prior consent.",
    "3. Personal data that you provide to us for the purpose of processing orders may be stored for up to 10 years for reasons of commercial and tax law.",
    "4. Your personal data is safe with us! They are encrypted by SSL and transmitted to us. Our websites are protected by technical measures against damage, destruction, unauthorized access.",
    "5. You will receive free information about your data stored by us at any time without giving reasons. You can at any time block, correct, or delete your data collected by us. You can revoke your consent to the collection and use of your data at any time without giving reasons. To do so, please contact: info@adamsynths.com",
    "6. Without the consent of the customer, the provider will not use the data of the customer for purposes of advertising, market, or opinion research.",
];
