import React from "react";

const random = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1)) + min;

export const Placeholder = ({width, height}: { width: any, height: any }) =>
    (
        <div className="gradient-placeholder" style={{width, height }}>
            <div className="gradient-placeholder-animation" style={{
                width,
                height,
                animationDelay: `${random(0, 0)}ms`,
                animationDuration: `${random(1000, 3000)}ms`,
            }} />
        </div>
    );


