import React from "react";
import {Col, Nav, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

type Props = {
    height: number;
}

export const Footer = React.memo(({height}: Props) =>
    <div style={{marginTop: height}}>
        <Row className="mt-4 footer-container">
            <Col md={4} className="mt-2 d-flex align-items-center justify-content-center">
                <Nav.Link as={Link} to="/">
                    <p>Adam Synths©2024</p>
                </Nav.Link>
            </Col>
            <Col md={4} className="mt-2">
                <Nav.Link as={Link} to="/terms-and-conditions">
                    <p>Terms and Conditions</p>
                </Nav.Link>
            </Col>
            <Col md={4} className="mt-2">
                <Nav.Link as={Link} to="/privacy-policy">
                    <p>Privacy Policy</p>
                </Nav.Link>
            </Col>
        </Row>
    </div>
);
