import React from "react";
import styled from "styled-components";
import {Button, Col, Row} from "react-bootstrap";
import {useGdpr} from "../state/gdpr";

const DesktopContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 24px;
  margin-right: 24px;
  width: 360px;
  padding: 16px;
  border: 1px solid white;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(10px);
  text-shadow: 0 0 1px black;
  box-shadow: 0 0 5px black;
`;

const MobileContainer = styled.div`
  position: fixed;
  bottom: 0;
  padding: 16px;
  left: 0;
  right: 0;
  border: 1px solid white;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(10px);
  text-shadow: 0 0 1px black;
`;

const getContent = (onClick: () => void) => <>
    This website uses cookies to ensure you get the best experience on our website.
    <br/>
    By using this website, you agree to our use of cookies. Thanks!
    <br/>
    <br/>

    <Row className="d-flex align-items-center justify-content-center">
        <Col>
            <a href="/privacy-policy" className="privacy-policy">
                <div className="learn-more">Learn More</div>
            </a>
        </Col>
        <Col className="d-flex justify-content-end">
            <Button variant="primary"
                    size="lg"
                    onClick={onClick}
                    type="submit">
                <b>OK, got it!</b>
            </Button>
        </Col>
    </Row>
</>;

export const Gdpr = () => {
    const {accept} = useGdpr();
    return (
        <>
            <DesktopContainer className="desktop-only">
                {getContent(accept)}
            </DesktopContainer>
            <MobileContainer className="mobile-only">
                {getContent(accept)}
            </MobileContainer>
        </>);
};


