import React from "react";
import {AdminEntityList} from "../admin-entity-list";

const fields = {
    id: "Id",
    firstName: "FirstName",
    lastName: "LastName",
    email: "Email",
};

export const AdminOrders = () => <AdminEntityList name="orders" fields={fields}/>;
