import React, {ReactNode} from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const FadeIn = ({children, nextDelay}: { children: ReactNode, nextDelay: () => object }) =>
    <Container style={nextDelay()}>
        {children}
    </Container>
