import React from "react";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {Container} from "react-bootstrap";

import {AdminTopBar} from "./admin-top-bar";
import {AuthCheck} from "./auth-check";

export const AdminBase = ({children}: { children: React.ReactNode }) =>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}>
        <AuthCheck>
            <AdminTopBar/>
            <Container>
                {children}
            </Container>
        </AuthCheck>
    </GoogleOAuthProvider>;
