import vcoBlock from "images/vco/as-vco-block.jpg";
import vcoBig from "images/vco/as-vco-0-big.jpg";
import vco0 from "images/vco/as-vco-0.jpg";
import vco1 from "images/vco/as-vco-1.jpg";
import vco2 from "images/vco/as-vco-2.jpg";
import vco3 from "images/vco/as-vco-3.jpg";
import vco4 from "images/vco/as-vco-4.jpg";
import vco5 from "images/vco/as-vco-5.jpg";

import vcfBlock from "images/vcf/as-vcf-block.jpg";
import vcfBig from "images/vcf/as-vcf-0-big.jpg";
import vcf0 from "images/vcf/as-vcf-0.jpg";
import vcf1 from "images/vcf/as-vcf-1.jpg";
import vcf2 from "images/vcf/as-vcf-2.jpg";
import vcf3 from "images/vcf/as-vcf-3.jpg";
import vcf4 from "images/vcf/as-vcf-4.jpg";
import vcf5 from "images/vcf/as-vcf-5.jpg";

import modBlock from "images/mod/as-mod-block.jpg";
import modBig from "images/mod/as-mod-0-big.jpg";
import mod0 from "images/mod/as-mod-0.jpg";
import mod1 from "images/mod/as-mod-1.jpg";
import mod2 from "images/mod/as-mod-2.jpg";
import mod3 from "images/mod/as-mod-3.jpg";
import mod4 from "images/mod/as-mod-4.jpg";
import mod5 from "images/mod/as-mod-5.jpg";

import envBlock from "images/env/as-env-block.jpg";
import envBig from "images/env/as-env-0-big.jpg";
import env0 from "images/env/as-env-0.jpg";
import env1 from "images/env/as-env-1.jpg";
import env2 from "images/env/as-env-2.jpg";
import env3 from "images/env/as-env-3.jpg";
import env4 from "images/env/as-env-4.jpg";
import env5 from "images/env/as-env-5.jpg";

import intBlock from "images/int/as-int-block.jpg";
import intBig from "images/int/as-int-0-big.jpg";
import int0 from "images/int/as-int-0.jpg";
import int1 from "images/int/as-int-1.jpg";
import int2 from "images/int/as-int-2.jpg";
import int3 from "images/int/as-int-3.jpg";
import int4 from "images/int/as-int-4.jpg";
import int5 from "images/int/as-int-5.jpg";

import mixBlock from "images/mix/as-mix-block.jpg";
import mixBig from "images/mix/as-mix-0-big.jpg";
import mix0 from "images/mix/as-mix-0.jpg";
import mix1 from "images/mix/as-mix-1.jpg";
import mix2 from "images/mix/as-mix-2.jpg";
import mix3 from "images/mix/as-mix-3.jpg";

import mulBlock from "images/mul/as-mul-block.jpg";
import mulBig from "images/mul/as-mul-0-big.jpg";
import mul0 from "images/mul/as-mul-0.jpg";
import mul1 from "images/mul/as-mul-1.jpg";
import mul2 from "images/mul/as-mul-2.jpg";
import mul3 from "images/mul/as-mul-3.jpg";
import mul4 from "images/mul/as-mul-4.jpg";

type Specs = { [key: string]: string };

export enum Badge {
    analog,
    hq,
    firmware,
    floating,
    dac,
    mixed,
    usbMidi,
    midi,
    passive
}

export type ModuleInfo = {
    title: string;
    model: string;
    modelNr: string;
    description: Array<string>;
    bigImage: string;
    images: Array<string>;
    manual: string;
    recall: string;
    block: string;
    specs: Specs;
    soundcloud: string;
    badges: Array<Badge>;
    price: number;
}

export const modules: Array<ModuleInfo> = [
    {
        title: "Voltage-Controlled Oscillator",
        model: "VCO",
        modelNr: "AS101",
        description: [
            "Turn it on and play! To tune the oscillator use the -/+1 octave tune potentiometer.",
            "We've enhanced the oscillator's versatility, recognizing the frequent need to adjust its pitch during live performances. Striking the right pitch can be challenging, which led us to integrate a +/-1 octave tuning potentiometer, marked with a piano keyboard layout. For seamless octave transitions, it offers a four-step switch, complemented by a fine-tune knob for precise pitch adjustments.",
            "Our oscillator is fully analog and temperature-compensated, produces square, saw, triangle, and sine waves. It features individual outputs for each waveform and includes a selectable waveform output for added versatility.",
            "All the waveforms are in the same phase and amplitude (10Vpp), allowing the simultaneous use of multiple waveforms from the same VCO without any phase cancellation or volume fluctuation.",
            "The pulse width can be adjusted between 10% and 90%. There’s a controllable PWM CV input too. The SAW compatible HARD SYNC input can be turned on/off. Besides the V/OCT input, there are two adjustable exponential CV inputs available for the modulation of the frequency. The whole range of frequency of the AS SYNTHS VCO is in between 16.352Hz and 8.372kHz (C0 – C9), so it means 10 octaves with +/-5 cents accuracy."
        ],
        manual: "/modules/vco/as-vco-manual.pdf",
        recall: "/modules/vco/as-vco-recall.pdf",
        block: vcoBlock,
        bigImage: vcoBig,
        images: [vco0, vco1, vco2, vco3, vco4, vco5],
        specs: {
            "Module depth": "33mm",
            "Module width": "16HP (80.4mm)",
            "Module weight": "210g",
            "Connector type": "10P IDC Eurorack",
            "Power consumption":
                "+12V / 60mA & -12V / 40mA",
            "Audio outputs": "HQ Analog",
            "Audio outputs range": "10Vpp",
            "CV inputs": "0V to +10V or -5V to +5V"
        },
        soundcloud: "https://w.soundcloud.com/player/?visual=false&url=https%3A%2F%2Fapi.soundcloud.com%2Fplaylists%2F1414003924&show_artwork=true&maxheight=1000&maxwidth=1200&auto_play=false&buying=true&liking=true&download=true&sharing=true&show_comments=true&show_playcount=true&show_user=true&color",
        badges: [Badge.hq, Badge.analog],
        price: 240
    },
    {
        title: "Low-Pass Voltage-Controlled Filter & Amplifier",
        model: "LOWPASS VCF & VCA",
        modelNr: "AS201",
        description: [
            "A modern, linear, 100% analogue filter, the heart & soul of a modular setup!",
            "We developed a modern, linear, 100% analog filter. It operates flawlessly in any circumstance and reacts 'musically' even in the most extreme setups. This filter is capable of processing multiple audio signals, as crafting a timbre often requires several oscillators, noise generators, and perhaps external sources. For this reason, we integrated a three-channel mixer into the module, along with the post-filter VCA.",
            "There are two additional adjustable CV inputs for frequency modulation, along with separate inputs for controlling resonance and VCA level. When you increase the resonance knob, you'll notice a subtle sinusoidal resonance. The sound remains cohesive; the filter does not begin to 'scream.' We incorporated a Resonance Boost circuit into the filter, ensuring that increasing the resonance does not diminish the depth of the lower frequencies.",
            "And guess what! You can overload the filter! Give it a try, and you’ll hear a very pleasant drive effect. This effect is based on the attributes of the filter’s own circuit, not on a built-in distortion. The input of the Freq CV2 can be assigned to the VCA CV input using its dedicated switch, allowing the same generator to modulate both the frequency of the filter and the amplitude of the VCA."
        ],
        manual: "/modules/vcf/as-vcf-manual.pdf",
        recall: "/modules/vcf/as-vcf-recall.pdf",
        block: vcfBlock,
        bigImage: vcfBig,
        images: [vcf0, vcf1, vcf2, vcf3, vcf4, vcf5],
        specs: {
            "Module depth": "33mm",
            "Module width": "16HP (80.4mm)",
            "Module weight": "215g",
            "Connector type": "10P IDC Eurorack",
            "Power consumption":
                "+12V / 60mA & -12V / 50mA",
            "Audio outputs": "HQ Audio",
            "Audio outputs range": "10Vpp",
            "CV inputs": "0V to +10V or -5V to +5V"
        },
        soundcloud: "https://w.soundcloud.com/player/?visual=false&url=https%3A%2F%2Fapi.soundcloud.com%2Fplaylists%2F1414003924&show_artwork=true&maxheight=1000&maxwidth=1200&auto_play=false&buying=true&liking=true&download=true&sharing=true&show_comments=true&show_playcount=true&show_user=true&color",
        badges: [Badge.hq, Badge.analog],
        price: 240
    },
    {
        title: "Modulation Tools",
        model: "MODULATION TOOLS",
        modelNr: "AS401",
        description: [
            "All the necessary tools in one module: SYNC LFO, NOISE GENERATOR, SAMPLE & HOLD, GLIDE, VCA & INVERTER.",
            "All through the project of developing this module, we created all those circuits one by one, which are an absolute necessity to create a sound. The purpose was to fit them all into one module. That’s how this composite and compact module was born, and got named AS SYNTHS MODULATION TOOLS. All the parts of this module have their own in- and output, but it also has several internal connections as well. These connections can be disconnected too, you just have to plug a cable into an input. So all the parts of this module are capable of working altogether or separately as well.",
            "The NOISE GENERATOR output connects to the SAMPLE & HOLD input. The SAMPLE & HOLD output connects into the GLIDE. The SYNC LFO triggers the SAMPLE & HOLD, so we get a synchable random generator. Using a switch you can select the waveform you wish to have on the input of the VCA & INVERTER. In addition, the VCA & INVERTER has its own level control potentiometer, own CV input, also has normal and inverse output as well.",
            "The SYNC LFO is capable of working in two different modes. Using the NORMAL mode the frequency can be controlled by the RATE potentiometer between 0,05Hz and 50Hz. In MATH mode the Rate potentiometer works as a divider, so the incoming sync pulse, or TAP can be divided or multiplied too. x1,5; x2; x3; x4; /1,5; /2; /3; /4",
            "Besides all of these features, the SYNC LFO has SYNC, RESET and CV inputs too, also has individual SQUARE and TRIANGLE outputs. You can adjust the CV input level, and select if you want it to affect either the RATE or the SHAPE. Using the SHAPE potentiometer, you can set the pulse width of the square wave, also the triangle wave can be adjusted either to saw and ramp form as well. With the CV input selected on RATE in MATH mode, you can switch between the DIVIDER settings from the keyboard of your synthesizer or sequencer. It gives you the possibility of changing or modifying the beats of the LFO, to the rhythm of your music. This gives you very exciting possibilities to compose rhythmic modulation."
        ],
        manual: "/modules/mod/as-modulation-manual.pdf",
        recall: "/modules/mod/as-modulation-recall.pdf",
        block: modBlock,
        bigImage: modBig,
        images: [mod0, mod1, mod2, mod3, mod4, mod5],
        specs: {
            "Module depth": "33mm",
            "Module width": "16HP (80.4mm)",
            "Module weight": "215g",
            "Connector type": "10P IDC Eurorack",
            "Power consumption":
                "+12V / 60mA & -12V / 50mA",
            "Audio outputs": "HQ Audio",
            "Audio outputs range": "10Vpp",
            "CV inputs": "0V to +10V or -5V to +5V"
        },
        soundcloud: "https://w.soundcloud.com/player/?visual=false&url=https%3A%2F%2Fapi.soundcloud.com%2Fplaylists%2F1414003924&show_artwork=true&maxheight=1000&maxwidth=1200&auto_play=false&buying=true&liking=true&download=true&sharing=true&show_comments=true&show_playcount=true&show_user=true&color",
        badges: [Badge.firmware, Badge.floating, Badge.dac, Badge.mixed, Badge.analog],
        price: 340
    },
    {
        title: "Dual Envelope Generator",
        model: "DUAL ENV",
        modelNr: "AS301",
        description: [
            "Dual envelope generator with a dedicated GATE DELAY to generate a second delayed envelope!",
            "For the creation of a sound it is absolutely a necessity to have an envelope generator. Moreover, in most cases you need to use two of it, one for the VCF, and another one for the VCA, or for other moulations perhaps. With this in mind our DUAL ENV came into existence, with an extra feature of the GATE DELAY function. Using the own dedicated potentiometer of this function you can make the second envelope to delay. Both of these envelope generators can be set-up for GATED or TRIGGERED mode. This way you can turn on/off the legato playing. With separate switches you can set the curves of the envelopes by choosing linear or exponential.",
            "For the sake of easy and convenient adjustment we divided the time potentiometers into two sections. This way you can set the time from completely turned down position to mid position 2ms to 1s, and from mid position to completely turned up position 1s to 10s. At the delay knob its 0s, 200ms, 2s. Using this method you can really fine tune the times. Just in case, if this wasn’t enough yet, you can find the inverse of the envelope signs on the outputs of the AS SYNTHS DUAL ENV. It means the positive output works from 0V to +10V, the inverse output works from 0V, to -10V. Moreover the second envelope has an OFFSET switch, so you can shift the output level. This way it can be 0V to 10V, or -5V to +5V",
            "All of these features make it possible to create more and more exciting modulations and the output of the second envelope becomes compatible with modulation inputs receiving -/+5V control voltage.",],
        manual: "/modules/env/as-dualenv-manual.pdf",
        recall: "/modules/env/as-dualenv-recall.pdf",
        block: envBlock,
        bigImage: envBig,
        images: [env0, env1, env2, env3, env4, env5],
        specs: {
            "Module depth": "33mm",
            "Module width": "16HP (80.4mm)",
            "Module weight": "215g",
            "Connector type": "10P IDC Eurorack",
            "Power consumption":
                "+12V / 90mA & -12V / 10mA",
            "Audio outputs": "True Rail-to-Rail",
            "Audio outputs range": "0V to +10V or -5V to +5V",
            "Digital inputs range": "0V /+5V",
            "Digital inputs pulse time": " min 1ms"
        },
        soundcloud: "https://w.soundcloud.com/player/?visual=false&url=https%3A%2F%2Fapi.soundcloud.com%2Fplaylists%2F1414003924&show_artwork=true&maxheight=1000&maxwidth=1200&auto_play=false&buying=true&liking=true&download=true&sharing=true&show_comments=true&show_playcount=true&show_user=true&color",
        badges: [Badge.firmware, Badge.floating, Badge.dac, Badge.mixed],
        price: 220
    },
    {
        title: "MIDI/CV Interface",
        model: "INTERFACE, MIDI & AUDIO BOARD",
        modelNr: "AS901, AS701",
        description: [
            "Our MIDI/CV Interface contains 4 analogue CV output, 8 digital output. You can connect to your DAW via USB, or to hardware instruments via MIDI.",
            "Each control output can be parameterized individually. The AS MIDI/CV Interface is a flexible controller module, which contains 4 analogue CV output, and 8 digital output. It can be connected by USB directly to the DAW, or to any other instrument/controller via MIDI. In the case of USB MIDI, it can operate as a MIDI interface, so it passes on the MIDI command coming from USB, to the MIDI OUT. For setting parameters, there are 6 navigation knobs, and a 2 x 16 character LCD. The equipment can save 8 different program settings.",
            "For the ergonomic shape the MIDI, and the USB connectors are not placed on the front, but instead they have an individual module, or can be fixed on the back of the synthesizer. All the analogue CV outputs are equipped with a 16 bit high speed DAC, and a precision OpAmp. These CV outputs independently can be NOTE, VELOCITY, AFTERTOUCH, PITCH BEND, or CONTROLLER outputs. The polarity of all the outputs can be adjusted, so this way in case of the NOTE setting the pitch control key can be reversed for example. Out of the four CV outputs on the #3 and #4 you can choose 0V / 10V, or -5V / +5V voltage interval. For example at a PITCH BEND it is a required parametrization.",
            "Digital outputs can be GATE, TRIG outputs, or SYNC, RESET, START / STOP outputs separately, on any MIDI channel. GATE can react on pressing or on releasing (S-TRIG) of the key. The TRIG can react on pressing, on releasing of the keys, or even both. The SYNC mode can be the standard PPQN SYNC for drum machines for example, but it has a DIVIDER as well, to choose the measure. All this from 1/1 to 1/32, even by triola and dotted beat too.",
        ],
        manual: "/modules/int/as-interface-manual.pdf",
        recall: "/modules/int/as-interface-recall.pdf",
        block: intBlock,
        bigImage: intBig,
        images: [int0, int1, int2, int3, int4, int5],
        specs: {
            "Module depth": "33mm",
            "Module width": "16HP (80.4mm)",
            "Module weight": "190g",
            "Connector type": "10P IDC Eurorack",
            "Power consumption":
                "+12V / 90mA & -12V / 10mA",
            "Audio outputs": "True Rail-to-Rail",
            "Audio outputs range": "0V to +10V or -5V to +5V",
            "Digital outputs pulse time": "2ms",
            "When digital out is": "Trig or MMC"
        },
        soundcloud: "https://w.soundcloud.com/player/?visual=false&url=https%3A%2F%2Fapi.soundcloud.com%2Fplaylists%2F1414003924&show_artwork=true&maxheight=1000&maxwidth=1200&auto_play=false&buying=true&liking=true&download=true&sharing=true&show_comments=true&show_playcount=true&show_user=true&color",
        badges: [Badge.firmware, Badge.floating, Badge.dac, Badge.mixed, Badge.usbMidi, Badge.midi],
        price: 340
    },

    {
        title: "Mixer",
        model: "MIX2GO-S",
        modelNr: "AS501",
        description: [
            "MIX2GO-S is an 8 HP mixer directly designed for the smallest system, containing an adjustable mono, an adjustable stereo and a unity gain stereo channel.",
            "Our MIX2GO-S is an 8 HP mixer directly designed for the smallest system, containing an adjustable mono, an adjustable stereo and a unity gain stereo channel. The mono channel has an aux send. The mixbus has adjustable stereo line, and headphone outputs. Also there’s a “one to three” passive multiples.",
            "Of the several functions it has, many can be customized by the user. Let’s see: The input gain of all the channels can be switched by the backside jumpers. It is recommended to remove the jumpers for receiving modular signals, in this case the input gain is 0dB. If you want to connect line level signal into one of the channels, then it’s recommended to use the jumpers, which allows for the +6db gain.",
            "The mono channel has an effect Send after mono channel volume control. For effect return you can use the stereo input or unity gain 2TR IN. Output level of the “send” is switchable by the backside jumper between 0dB/-6dB, so as not to overload the guitar level effects. Besides these an HPF can be useful for the Delay and Reverb effects. It’s built in the AS SYNTHS MIX2GO-S as well, and can be switched ON/OFF with a backside jumper too.",
            "It’s important to note that if you use the effect in auxiliary mode, then turn the DRY/WET potentiometer into WET position on the effect processor.",
            "Summary: the MIX2GO-S is a very useful outboard. Fits in easily into any system, thanks to the line level output it can be connected into any audio interface and mixing desk. Also it’s true on contrariwise, because besides receiving the level of modular signal, it’s capable of receiving line level signal as well.",
            "You can find the output of the MIX”GO-S on the module’s back panel too to be prepared for internal routing. The MIX2GO-S does not contain the AS701 Audio and MIDI Outboard.",
        ],
        manual: "/modules/mix/as-mix2gos-manual.pdf",
        recall: "/modules/mix/as-mix2gos-recall.pdf",
        block: mixBlock,
        bigImage: mixBig,
        images: [mix0, mix1, mix2, mix3],
        specs: {
            "Module depth": "33mm",
            "Module width": "8HP (40.3mm)",
            "Module weight": "120g",
            "Connector type": "10P IDC Eurorack",
            "Power consumption":
                "+12V / 30mA & -12V / 30mA",
            "Audio in & out": "HQ Audio",
            "Audio in & out range": "10Vpp",
        },
        soundcloud: "https://w.soundcloud.com/player/?visual=false&url=https%3A%2F%2Fapi.soundcloud.com%2Fplaylists%2F1414003924&show_artwork=true&maxheight=1000&maxwidth=1200&auto_play=false&buying=true&liking=true&download=true&sharing=true&show_comments=true&show_playcount=true&show_user=true&color",
        badges: [Badge.hq, Badge.analog],
        price: 150
    },
    {
        title: "Multiples 8",
        model: "MULTIPLES 8",
        modelNr: "AS8M",
        description: [
            "Introducing passive multipliers: essential tools for shaping your analog modular synthesizer's sound.",
            "These components duplicate and distribute voltage signals with precision and transparency, maintaining audio integrity unlike active alternatives.",
            "Unlock a world of sonic possibilities with passive multipliers. Create complex modulation schemes and harmonic textures effortlessly, enriching your sound palette with evolving timbres and rich tones. ",
            "Seamlessly integrate multiple modules and explore intricate patch configurations with their versatile signal distribution capabilities.",
            "Embrace the spirit of experimentation and customization with passive multipliers. Whether you're a seasoned synthesist or just starting, these reliable components are a must-have for any modular setup.",
            "Elevate your sound and expand your creative horizons with passive multipliers – the foundation of analog modular synthesis.",
        ],
        manual: "/modules/mul/as-mul-manual.pdf",
        recall: "/modules/mul/as-mul-recall.pdf",
        block: mulBlock,
        bigImage: mulBig,
        images: [mul0, mul1, mul2, mul3, mul4],
        specs: {
            "Module depth": "13mm",
            "Module width": "4HP (20mm)",
            "Module weight": "35g",
            "No power consumption": ""
        },
        soundcloud: "https://w.soundcloud.com/player/?visual=false&url=https%3A%2F%2Fapi.soundcloud.com%2Fplaylists%2F1414003924&show_artwork=true&maxheight=1000&maxwidth=1200&auto_play=false&buying=true&liking=true&download=true&sharing=true&show_comments=true&show_playcount=true&show_user=true&color",
        badges: [Badge.passive],
        price: 20
    },
    {
        title: "Multiples 16",
        model: "MULTIPLES 16",
        modelNr: "AS16M",
        description: [
            "Introducing passive multipliers: essential tools for shaping your analog modular synthesizer's sound.",
            "These components duplicate and distribute voltage signals with precision and transparency, maintaining audio integrity unlike active alternatives.",
            "Unlock a world of sonic possibilities with passive multipliers. Create complex modulation schemes and harmonic textures effortlessly, enriching your sound palette with evolving timbres and rich tones. ",
            "Seamlessly integrate multiple modules and explore intricate patch configurations with their versatile signal distribution capabilities.",
            "Embrace the spirit of experimentation and customization with passive multipliers. Whether you're a seasoned synthesist or just starting, these reliable components are a must-have for any modular setup.",
            "Elevate your sound and expand your creative horizons with passive multipliers – the foundation of analog modular synthesis.",
        ],
        manual: "/modules/mul/as-mul-manual.pdf",
        recall: "/modules/mul/as-mul-recall.pdf",
        block: mulBlock,
        bigImage: mulBig,
        images: [mul0, mul1, mul2, mul3, mul4],
        specs: {
            "Module depth": "13mm",
            "Module width": "4HP (20mm)",
            "Module weight": "35g",
            "No power consumption": ""
        },
        soundcloud: "https://w.soundcloud.com/player/?visual=false&url=https%3A%2F%2Fapi.soundcloud.com%2Fplaylists%2F1414003924&show_artwork=true&maxheight=1000&maxwidth=1200&auto_play=false&buying=true&liking=true&download=true&sharing=true&show_comments=true&show_playcount=true&show_user=true&color",
        badges: [Badge.passive],
        price: 35
    },
];
