import React from "react";
import {Container} from "react-bootstrap";

import {BasePage} from "pages/base-page";
import {MainTitle} from "components/styles";
import {FadeIn} from "components/fade-in";
import {useDelayedFadeIn} from "utils/animation-delay";
import {tos} from "content/tos";

export const TermsAndConditions = () => {
    const {nextDelay} = useDelayedFadeIn({});
    return <BasePage>
        <Container>
            <MainTitle className="mb-4">
                <FadeIn nextDelay={nextDelay}>
                    Terms and Conditions
                </FadeIn>
            </MainTitle>
            {tos.map((section, i) =><FadeIn key={i} nextDelay={nextDelay}>
                <div dangerouslySetInnerHTML={{ __html: section }} />
                <br/>
            </FadeIn>)}
        </Container>
    </BasePage>;
}
