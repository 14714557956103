import React, {ReactNode} from "react";

import {countries} from "content/countries";
import {UserOrder} from "state/order-type";

type Props = {
    order: UserOrder;
    Col1: (children: ReactNode) => ReactNode;
    Col2: (children: ReactNode) => ReactNode;
    Row: (children: ReactNode) => ReactNode;
}

export const UserDataRenderer = ({order, Col1, Col2, Row}: Props) => {
    const {
        firstName,
        lastName,
        email,
        country,
        addressLine1,
        addressLine2,
        postalCode,
        city,
        state,
        paymentOption,
        message,
        source,
        sourceOther

    } = order

    const countryInfo = countries.find(c => c.code2 === country)!;
    const formattedState = countryInfo.states.length > 0 && state !== "" ? `${state},` : "";
    const data = [
        {name: "Name", value: `${firstName} ${lastName}`},
        {name: "Email", value: email},
        {
            name: "Address",
            value: `${addressLine1}\n${addressLine2.trim()} ${postalCode} ${city} \n ${formattedState} ${countryInfo?.name}`
        },
        {name: "Payment option", value: paymentOption},
        {name: "Message", value: message},
        {name: "Source", value: source},
        {name: "Source Other", value: sourceOther}
    ]
    return <>
        {data.map((d) => d.value !== "" ?
            <div key={d.name}>{Row([Col1(<b>{d.name}:</b>), Col2(d.value)])}</div> :
            null)}
    </>;
}

