import React from "react";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

import {FadeIn} from "components/fade-in";
import {GetVisible} from "components/get-visible";
import {useDelayedFadeIn} from "utils/animation-delay";

export const Ingredients = () => {
    const {nextDelay} = useDelayedFadeIn({});

    return <GetVisible
        fader={nextDelay}
        builder={(visible, immediately, nd) =>
            <Col md={12} className="mt-4">
                <FadeIn nextDelay={nd}>
                    <h4>Ingredients</h4>
                </FadeIn>
                <Row className="justify-content-between py-2">
                    <FadeIn nextDelay={nd}>
                        <Link to={`/modules/MIX2GO-S`}><b>➤ 1 x MIX2GO-S:</b> an 8HP mixer module featuring an adjustable mono & stereo and a unity gain stereo channel</Link>
                    </FadeIn>
                </Row>
                <Row className="justify-content-between py-2">
                    <FadeIn nextDelay={nd}>
                        <Link to={`/modules/INTERFACE, MIDI & AUDIO BOARD`}><b>➤ 1 x INTERFACE:</b> MIDI/CV interface module with 4 analogue
                            CV & 8
                            digital out</Link>
                    </FadeIn>
                </Row>
                <Row className="justify-content-between py-2">
                    <FadeIn nextDelay={nd}>
                        <Link to={`/modules/MODULATION TOOLS`}><b>➤ 1 x MODULATION TOOLS:</b> all in one module with sync
                            LFO, Noise Generator, Sample & Hold, Glide, VCA & INVERTER</Link>
                    </FadeIn>
                </Row>
                <Row className="justify-content-between py-2">
                    <FadeIn nextDelay={nd}>
                        <Link to={`/modules/VCO`}><b>➤ 2 x VCO:</b> fully analog and temperature-compensated oscillator
                            module</Link>
                    </FadeIn>
                </Row>
                <Row className="justify-content-between py-2">
                    <FadeIn nextDelay={nd}>
                        <Link to={`/modules/LOWPASS VCF & VCA`}><b>➤ 1 x LOWPASS VCF & VCA:</b> 100% analogue filter
                            module with a VCA included</Link>
                    </FadeIn>
                </Row>
                <Row className="justify-content-between py-2">
                    <FadeIn nextDelay={nd}>
                        <Link to={`/modules/DUAL ENV`}><b>➤ 1 x DUAL ENV:</b> dual envelope generator with a dedicated
                            GATE DELAY</Link>
                    </FadeIn>
                </Row>
            </Col>
        }
    />;
}

