import React, {useCallback, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Zoom} from "yet-another-react-lightbox/plugins";
import Lightbox from "yet-another-react-lightbox";

import {modules} from "content/modules";
import {useDelayedFadeIn} from "utils/animation-delay";
import {FadeIn} from "components/fade-in";
import {BasePage} from "pages/base-page";
import {Clickable, MainTitle} from "components/styles";
import {ProgressiveImage} from "components/progressive-image";
import {MetaInfo} from "components/meta-info";
import {ModuleDetailRow} from "./module-detail-row";
import {Features} from "./features";
import {SoundCloud} from "./soundcloud";
import {OtherModules} from "./other-modules";
import {ModuleOrder} from "./module-order";

type Props = {
    model: string;
}

export const ModuleDetail = ({model}: Props) => {
    const module = modules.find(m => m.model === model)!;
    const {nextDelay} = useDelayedFadeIn({});

    const {bigImage, description, title, images} = module;
    const [isGalleryOpen, setIsGalleryOpen] = useState(-1);
    const openGallery = useCallback((index: number) => () => setIsGalleryOpen(index), []);
    const slides = modules.flatMap(m => m.images.map(i => ({src: i})));

    return <>
        <Lightbox
            open={isGalleryOpen > -1}
            close={() => setIsGalleryOpen(-1)}
            index={isGalleryOpen}
            slides={slides}
            plugins={[Zoom]}
        />
        <MetaInfo
            title={`Adam Synths - ${module.title} - Handcrafted in Valencia, Spain`}
            description={module.description[0]}
            image={module.bigImage}
            imageW={1296}
            imageH={861}
            path={`/modules/${module.model}`}
        />
        <BasePage>
            <Container>
                <FadeIn nextDelay={nextDelay}>
                    <Clickable onClick={openGallery(0)}>
                        <ProgressiveImage src={bigImage} aspectRatio={1000 / 664} title={title}/>
                    </Clickable>
                </FadeIn>
                <FadeIn nextDelay={nextDelay}>
                    <MainTitle className="mb-5 mt-4">{`${title}`}</MainTitle>
                </FadeIn>
                {description.map((description, i) =>
                    <ModuleDetailRow
                        key={i}
                        fadeDelay={i}
                        description={description}
                        isRight={i % 2 === 0}
                        openGallery={openGallery((i + 1) % images.length)}
                        image={images[(i + 1) % images.length]
                        }/>
                )}
                <Features module={module}/>
                <SoundCloud module={module}/>
                <Row>
                    <Col md={6} className="mt-4">
                        <OtherModules module={module}/>
                    </Col>
                    <Col md={6} className="mt-4 mb-4 d-flex flex-column align-items-start justify-content-start">
                        <ModuleOrder module={module}/>
                    </Col>
                </Row>
            </Container>
        </BasePage>
    </>
}
