import {UserOrder, QuantityMap} from "state/order-type";
import {salesTax} from "content/sales-tax";

export const totalCalc = (quantityMap: QuantityMap, lookup: (model: string) => number | undefined): number => Object.entries(quantityMap).reduce((acc, [model, quantity]) => {
    const price = lookup(model)
    if (!price) {
        return acc;
    }
    return acc + (price * quantity);
}, 0);

export const getVat = (orderState: UserOrder) => {
    const country = salesTax[orderState.country];
    if (!country) {
        return 0;
    }
    const states = country.states;
    if (!states || Object.keys(states).length === 0) {
        return country.rate ?? 0;
    }

    const state = states[orderState.state];
    return state?.rate ?? country.rate ?? 0;
};
