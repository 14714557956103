import React from "react";
import {Col, Row} from "react-bootstrap";

import {ModuleInfo} from "content/modules";
import {FadeIn} from "components/fade-in";
import {GetVisible} from "components/get-visible";
import {useDelayedFadeIn} from "utils/animation-delay";

type Props = {
    module: ModuleInfo;
}

export const SoundCloud = ({ module: {soundcloud, title, model}}: Props) => {
    const {nextDelay} = useDelayedFadeIn({});

    return <GetVisible
        fader={nextDelay}
        builder={(visible, immediately, nd) => !visible ? <div/> :
            <FadeIn nextDelay={nd}>
                <Row className="mt-4">
                    <Col md={12}>
                        <h4>Take a listen</h4>
                        <iframe title={`${title} - ${model}`}
                                loading="lazy"
                                width="100%"
                                height="512"
                                allow="autoplay"
                                src={soundcloud}/>
                    </Col>
                </Row>
            </FadeIn>}/>;
}

