export const tos = ["These terms and conditions apply to every user (also named „you“, „user“ or „buyer“) of adamsynths.com website (further – the Website) and to all purchases done via the Website.",
    "1. Owner of the website and personal data processing supervisor is <b>BUMBLEBEE D.A.O. </b> (further – Adam Synths); a limited liability company with address at 10145, Kesklinna linnaosa, Tornimäe tn 3// 5// 7, Tallinn, Harju maakond,VAT nr: EE102670545.",

    "<b>2. Intellectual property rights</b>",

    "2.1. All content of the Website is intellectual property of Adam Synths or respective third parties.",

    "2.2. Use of any content of the website in any manner is subject to receiving a permission form Adam Synths, and form respective third parties, if required.",

    "3. User’s account",

    "3.1. You will be required to create an user’s account to make purchases via the Website, as well as to personalize your user’s experience of the Website.",

    "3.2. You are responsible for keeping all the access information of your user’s account so that third parties cannot get hold of it. You undertake all risks and liability for the actions done using your user’s account.",

    "3.3. You should ensure that the e-mail indicated in your user’s account data is valid and functioning. We will use it to send to you important information regarding your purchases and use for other communication with you.",

    "3.4. Adam Synths may stop activity of any user’s account at any time if it is used for actions that Adam Synths consider illegal or unethical, or not in line with these terms and conditions. Adam Synths may discontinue user’s account service, may cancel any user’s account and erase information related thereto at any time upon its discretion.",

    "<b>4. User’s age</b>",

    "4.1. Adam Synths proposes selling of its products only to persons who are 18 years old or older; Adam Synths may cancel any transaction if it learns that the buyer is younger than 18 years.",

    "4.2. Adam Synths may close any user’s account if Adam Synths learns that the user at the moment is younger than 18 years.",

    "<b>5. Prices of goods, ordering and delivery</b>",

    "5.1. Unless otherwise stated in the website, the price for any goods sold via the Website is indicated inclusive of all taxes payable on territory of the Republic of Estonia, and including value added tax (VAT).",

    "5.2. In addition to the price, processing, packaging and shipping costs may be payable. These are indicated separately before you agree to purchase.",

    "5.3. In rare occasions prices of the goods indicated on the Website may not correspond to actual prices or may be unavailable. In such cases Adam Synths will act in one of the ways of its choice:",

    "5.3.1. contact you to receive your agreement for delivery of the goods for the right price, and will proceed with delivery only after you agree.",

    "5.3.2. cancel the order and inform you about the cancellation.",

    "5.4. You may order the goods by using the shop functionality available on the Website. You order the goods by placing them into the shopping cart and proceeding to checkout, and paying for the goods in the cart and for their shipment. The order is only accepted when the payment has been received. Even if Adam Synths may be aware of the goods in your shopping cart, placing of the goods therein does not constitute an order and causes no obligations to you or to Adam Synths.",

    "5.5. Adam Synths will ship your ordered goods within twenty-one working day (except pre-orders) after receiving your payment; to the address you have indicated as the delivery address during checkout process. Duration of the delivery depends on the selected mode of delivery and performance of the carrier, and the customs offices. You undertake all risks for indicating of an incomplete or incorrect address, and for loss of the delivery or delivery to a wrong recipient resulting therefrom. In case Adam Synths may think you have provided incorrect or incomplete address, Adam Synths will contact you to receive your confirmation of the address and may halt shipment process until the moment Adam Synths receives your confirmation.",

    "5.6. You must ensure receiving of the goods at their destination address. Returned deliveries will only be shipped repeatedly after Adam Synths has received payment for the costs of the repeated shipping.",

    "<b>6. Warranty obligations and consumer’s right of refusal</b>",

    "6.1. Adam Synths provides two years limited warranty for the goods it sells, except for the do-ityourself kits, valid from the moment of delivery of the goods.",

    "6.2. Adam Synths warrants that the goods covered by the warranty shall be free of faults in materials and work. Adam Synths shall repair or replace the faulty goods or shall refund their purchase price, if you send to Adam Synths your notice on the fault, and the faulty goods within the warranty period. Except for the aforesaid, Adam Synths will have no other liability for the faults found in the goods. Compensation of loss and damages resulting from faulty or malfunctioning goods are expressly excluded.",

    "6.3. The warranty is void if there are traces of mechanical or chemical impact on the goods, traces of attempts to disassemble or modify the goods, or traces of immersion of goods into any liquids, or placing of the goods into any chemically aggressive environment (fumes, vapours, etc.), or the goods were used not in compliance with the terms provided in the user’s manual.",

    "6.4. The provisions of clauses 6.1. to 6.3. do not limit the statutory rights of you as a consumer.",

    "6.5. If you qualify as a consumer according to the applicable laws, you have the statutory right of refusal in relation to goods that you purchase from Adam Synths. You may exercise them as provided in laws of the Republic of Estonia. Within scope of your exercising your rights of refusal",

    "Adam Synths shall accept from you the goods that are in good condition, without any external damage or modification and with full set of accessories and documentation. In case of your refusal Adam Synths shall refund to you the purchase price and respective part of the delivery costs you paid before the purchase. You will have to take care of and pay for delivery of the returned goods to Adam Synths. The money will be returned in the same way you have paid it (i.e., to your PayPal account or to your bank account). You may be required to provide additional information to process the payment.",

    "<b>7. Liability</b>",

    "7.1. Adam Synths undertakes no liability regarding the information provided on the Website. The information is provided without express or implied warranty for its correctness, completeness or suitability for particular purpose.",

    "7.2. The Website is made available as it is at any particular moment. Adam Synths undertakes no express or implied warranty regarding accessibility of the Website at any particular time. You use the Website, its services and information provided on the Website undertaking all the risks and costs related thereto.",

    "7.3. Adam Synths is not liable for malfunction of any communication networks that may result in malfunctioning of the Website’s functionality, including but not limited to, online purchases and submission of any data.",

    "7.4. Adam Synths shall apply the maximum effort to ensure that the descriptions of the goods on the Website are correct. Specifications of the goods may change without notice. In case of any incorrectness of the information that leads to your purchasing of undesired goods, the only compensation available to you will be refund of the purchase price and respective part of the delivery costs, and return delivery costs, upon receiving the unused goods that you have purchased.",

    "7.5. If any of the limitations of liability provided in these Terms and Conditions appears to be unenforceable under the applicable laws, the provision is not however cancelled, and shall be applied to the maximum extent permitted under the applicable laws.",

    "<b>8. Personal data</b>",

    "8.1. You allow Adam Synths to process your personal data you provide and the personal data that Adam Synths may collect about you in relation to your visiting the Website. Adam Synths may gather the your personal data in the following ways:",

    "8.1.1. from the user during setup of the user’s account or during online purchases.",

    "8.1.2. from the user’s computer by means of so called „cookie files“.",

    "8.1.3. from third parties involved in processing of the order.",

    "8.2. Adam Synths may use the collected data to process your purchases and to deliver to you the information Adam Synths thinks you might be interested in, including but not limited to, information on Adam Synths’ and its partners’ products and services.",

    "8.3. You may make changes in your personal data contacting Adam Synths at the address provided in clause 1 of these terms and conditions.",

    "<b>9. Applicable laws and dispute resolution</b>",

    "9.1. All relation between Adam Synths and you shall be examined according to laws applicable on the territory of the Republic of Estonia.",

    "9.2. All disputes between you and Adam Synths shall be resolved in courts of the Republic of Estonia. Court of Adam Synths‘ registered address shall be the court of the first instance.",

    "<b>10. Amendments to these Terms and Conditions</b>",

    "10.1. Adam Synths may amend or otherwise modify these Terms and Conditions at any time, without warning. Adam Synths may require that you agree to the modified Terms and Conditions and may discontinue provision of its services to you of you disagree.",

    "10.2. Adam Synths may discontinue its entire operations or any part thereof at any time, without warning.",

    "10.3. Adam Synths shall not compensate any loss or damage caused by modifications in these Terms and Conditions, nor any loss or damage related to full or partial discontinuation of its services or operations, for any reason whatsoever.",
];
