import React, {useEffect, useRef, useState} from "react";
import {Spinner} from "react-bootstrap";

import {TopBar} from "components/top-bar";
import {updateOrderAction, useOrder} from "state/order";
import {Center} from "components/styles";
import {baseURL} from "utils/base-url";
import {Footer} from "components/footer";
import {Gdpr} from "components/gdpr";
import {useGdpr} from "state/gdpr";

const heightThreshold = 184;

export const BasePage = ({children}: { children: React.ReactNode }) => {
    const {orderState, dispatch} = useOrder();
    const {isAccepted} = useGdpr();
    const [initialized, setInitialized] = useState(false);
    const [height, setHeight] = useState<number>(0);
    const ref = useRef<HTMLDivElement | null>(null)

    // To push footer to the bottom if the content is too short
    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                if (entry.target === ref.current) {
                    const wHeight = window.innerHeight;
                    const cHeight = entry.contentRect.height;
                    const h = cHeight < wHeight - heightThreshold ? wHeight - cHeight - heightThreshold : 0;
                    setHeight(h);
                }
            }
        });

        ref.current && resizeObserver.observe(ref.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [children]);

    useEffect(() => {
        if (orderState.country !== "") {
            setInitialized(true);
            return;
        }
        (async () => {
            try {
                const response = await fetch(`${baseURL()}/api/country`);
                const jsonResponse = await response.json();
                const country = jsonResponse["country"];

                dispatch(updateOrderAction({
                    ...orderState,
                    country
                }));
            } catch (e) {
                console.error('Failed to fetch country: ', e);
            }
            setInitialized(true);
        })();
    }, [orderState, dispatch])

    if (!initialized) {
        return <Center><Spinner animation="border"/></Center>;
    }

    return <>
        <TopBar/>
        <div ref={ref}>
            {children}
        </div>
        {!isAccepted ? <Gdpr/> : null}
        <Footer height={height}/>
    </>;
}
