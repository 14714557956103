import React from "react";
import {Container} from "react-bootstrap";

import {modules} from "content/modules";
import {BasePage} from "pages/base-page";
import {ModuleRow} from "./module-row";
import {CheckSynth} from "./check-synth";
import {synths} from "content/synths";
import {MetaInfo} from "components/meta-info";

export const Modules = () =>
    <BasePage>
        <MetaInfo
            title="Adam Synths - Analog Modules - Handcrafted in Valencia, Spain"
            description="Adam Synths - Analog Modules - Handcrafted in Valencia, Spain"
            image={modules[0].bigImage}
            imageW={1296}
            imageH={861}
            path="/modules/"
        />
        <Container>
            {modules.map((m, i) =>
                <ModuleRow
                    key={i}
                    fadeDelay={i}
                    info={m}
                    isRight={i % 2 === 0}
                />)}
            {synths.map((s, i) =>
                <CheckSynth key={s.model}
                            synth={s}
                            fadeDelay={modules.length}
                            isRight={(modules.length + i) % 2 === 0}/>)
            }
        </Container>
    </BasePage>
