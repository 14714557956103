import React from "react";
import {Row} from "react-bootstrap";

import {ModuleInfo, modules} from "content/modules";
import {useDelayedFadeIn} from "utils/animation-delay";
import {FadeIn} from "components/fade-in";
import {GetVisible} from "components/get-visible";
import {Link} from "react-router-dom";

type Props = {
    module: ModuleInfo;
}

export const OtherModules = ({module: {model}}: Props) => {
    const otherModules = modules.filter(m => m.model !== model);
    const {nextDelay} = useDelayedFadeIn({});

    return <GetVisible
        fader={nextDelay}
        builder={(visible, immediately, nd) => !visible ? <div/> :
            <FadeIn nextDelay={nd}>
                <h4>
                    <FadeIn nextDelay={nd}>
                        Plays well with
                    </FadeIn>
                </h4>
                {otherModules.map(m =>
                    <Link key={m.model} to={`/modules/${m.model}`}>
                        <Row className="mt-4">
                            <FadeIn nextDelay={nd}>
                                ➤ {m.title}
                            </FadeIn>
                        </Row>
                    </Link>
                )}
            </FadeIn>}/>;
}

