import React from "react";
import {Col, Row} from "react-bootstrap";
import {DashSquare, PlusSquare} from "react-bootstrap-icons";
import {Link} from "react-router-dom";

import {ThumbWrapper, ItemWrapper, Count, Price} from "../styles";
import {ProgressiveImage} from "components/progressive-image";
import {formatPrice} from "utils/format-price";
import {SlidingNumber} from "components/slide-up-number";
import {Button, Title} from "pages/styles";

type Props = {
    minusEnabled: boolean;
    plusEnabled: boolean;
    image: string;
    title: string;
    model: string;
    price: number;
    increase: () => void;
    decrease: () => void;
    quantity: number;
    isSynth: boolean;
    readOnly: boolean;
}

export const OrderItem = ({
                              minusEnabled,
                              plusEnabled,
                              image,
                              price,
                              title,
                              model,
                              decrease,
                              increase,
                              quantity,
                              isSynth,
                              readOnly
                          }: Props) => {
    const to = isSynth ? '/' : `/modules/${model}`;
    const isDesktop = window.innerWidth > 992;
    return <ItemWrapper>
        <Row>
            <Col md={readOnly ? 12 : 10} className="flex-row d-flex align-items-center justify-content-between">
                {readOnly ? null :
                    <Link to={to}>
                        <ThumbWrapper $enabled={minusEnabled}>
                            <ProgressiveImage src={image} aspectRatio={1} title={title}/>
                        </ThumbWrapper>
                    </Link>
                }

                <Title to={to}
                       className={readOnly ? "" : "ms-2"}
                       $enabled={minusEnabled}>
                    {!readOnly ? <b>{model}</b> : <b>{quantity} x {model}</b>}
                </Title>
                <div className="desktop-only">
                    <Price $enabled={minusEnabled}>
                        <SlidingNumber value={price * Math.max(quantity, 1)} formatter={formatPrice}
                                       $justifyContent="end"/>
                    </Price>
                </div>
            </Col>
            {!readOnly && isDesktop ?
                <Col md={2} className="flex-row d-flex align-items-center justify-content-end">
                    <Button $enabled={minusEnabled} onClick={minusEnabled ? decrease : undefined}>
                        <DashSquare size={32}/>
                    </Button>

                    <Count $enabled={minusEnabled}>
                        <SlidingNumber value={quantity}/>
                    </Count>

                    <Button $enabled={plusEnabled} onClick={plusEnabled ? increase : undefined}>
                        <PlusSquare size={32}/>
                    </Button>
                </Col> : null}
        </Row>
        {!readOnly && !isDesktop ?
            <Row>
                <Col className="mb-3 flex-row d-flex align-items-center justify-content-end">
                    <Price $enabled={minusEnabled} className="me-4">
                        <SlidingNumber value={price * Math.max(quantity, 1)} formatter={formatPrice}
                                       $justifyContent="end"/>
                    </Price>
                    <Button $enabled={minusEnabled} onClick={minusEnabled ? decrease : undefined}>
                        <DashSquare size={32}/>
                    </Button>

                    <Count $enabled={minusEnabled}>
                        <SlidingNumber value={quantity}/>
                    </Count>

                    <Button $enabled={plusEnabled} onClick={plusEnabled ? increase : undefined}>
                        <PlusSquare size={32}/>
                    </Button>
                </Col>
            </Row>: null}

    </ItemWrapper>;
};


