import React from "react";
import {useNavigate, useParams} from "react-router-dom";

import {Center, MainTitle} from "components/styles";
import {useUserAdmin} from "state/admin";
import {AdminBase} from "../admin-base";
import {AdminUserDetailBody} from "./admin-user-detail-body";
import {Button, Col, Row} from "react-bootstrap";

export const AdminUserDetails = () => {
    const {id} = useParams();
    const {user} = useUserAdmin(id);
    const navigate = useNavigate();

    const goBack = () => navigate('/admin/users');

    if (!user) {
        return <AdminBase><Center>No user for this id</Center></AdminBase>;
    }

    return <AdminBase>
        <MainTitle>
            {user?.firstName ?? "N/A"} {user?.lastName ?? "N/A"}
        </MainTitle>
        <AdminUserDetailBody user={user} />
        <Row className="mt-4">
            <Col className="mb-4 d-flex flex-column align-items-stretch justify-content-center">
                <Button variant="info"
                        size="lg"
                        onClick={goBack}
                        type="submit">
                    <b>Back</b>
                </Button>
            </Col>
        </Row>
    </AdminBase>;

}
