import React, {ReactNode, useEffect, useRef, useState} from "react";
import styled from "styled-components";

import {Fader} from "utils/animation-delay";

const Container = styled.div`
  width: 100%;
  height: 100%;
`

type Props = {
    builder: (visible: boolean, immediately: boolean, fader: Fader) => ReactNode;
    fader?: () => object;
}

const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: [0,1],
};

const noop = () => ({});

export const GetVisible = ({builder, fader = noop}: Props) => {
    const [visible, setVisible] = useState(false);
    const timestamp = useRef(0);
    const immediately = useRef(false);
    const elementRef = useRef(null);
    const currentFader = useRef(noop);

    useEffect(() => {
        timestamp.current = Date.now();
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const diff = Date.now() - timestamp.current;
                    immediately.current = diff < 100;
                    currentFader.current = fader;
                    setVisible(true);
                }
            });
        }, observerOptions);

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }
        const unob = elementRef.current;
        return () => {
            if (unob) {
                observer.unobserve(unob);
            }
        };
    }, [fader]);

    return <Container ref={elementRef} className={visible ? "fade-in-deep" : "hidden"}>
        {builder(visible, immediately.current, currentFader.current)}
    </Container>
}
