import React, {useState} from 'react';
import styled from 'styled-components';
import {Placeholder} from "./placeholder";
import {AspectContainer} from "./styles";

type Props = {
    src: string;
    title: string;
    aspectRatio: number;
}

const Absolute = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

const Image = styled.img<{ $loaded: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  min-width: 100%;
  opacity: ${props => (props.$loaded ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`;


export const ProgressiveImage = ({src, title, aspectRatio}: Props) => {
    const [loaded, setLoaded] = useState(false);

    return (
        <AspectContainer $aspectRatio={aspectRatio}>
            {!loaded ?
                <Absolute>
                    <Placeholder width={"100%"} height={"100%"}/>
                </Absolute> : null
            }
            <Image
                src={src}
                alt={title}
                $loaded={loaded}
                onLoad={() => setLoaded(true)}
            />
        </AspectContainer>
    );
};


