import React from "react";
import {Container} from "react-bootstrap";

import {BasePage} from "pages/base-page";
import {MetaInfo} from "components/meta-info";
import {synths} from "content/synths";


export const RecallEditor = () =>
    <BasePage>
        <MetaInfo
            title="Adam Synths - Contact Us - Handcrafted in Valencia, Spain"
            description="Adam Synths - Contact Us - Handcrafted in Valencia, Spain"
            image={synths[0].bigImage}
            imageW={1920}
            imageH={1080}
            path="/contact"
        />
        <Container>
            <iframe className="recall-iframe" src="https://patch-editor.adamsynths.com/" width="100%" height="100%" title="ASR Recall editor">
            </iframe>
        </Container>
    </BasePage>
