import React, {useCallback} from "react";
import {Button, Col, Row} from "react-bootstrap";

import {GetVisible} from "components/get-visible";
import {useDelayedFadeIn} from "utils/animation-delay";
import {changeQuantityAction, useOrder} from "state/order";
import {useNavigate} from "react-router-dom";
import {SynthInfo} from "content/synths";
import {formatPrice} from "utils/format-price";

type Props = {
    synth: SynthInfo;
}

export const SynthOrder = ({synth}: Props) => {
    const {dispatch} = useOrder();
    const navigate = useNavigate();

    const {nextDelay} = useDelayedFadeIn({});

    const order = useCallback(() => {
        dispatch(changeQuantityAction(synth.model, false, 1));
        navigate(`/order`);
    }, [dispatch, navigate, synth.model]);

    return <GetVisible
        fader={nextDelay}
        builder={(visible, immediately, nd) =>
            <Row className="mt-4 mb-4">
                <Col md={6}>
                    <h4>Order Now</h4>
                    <h5><b>{formatPrice(synth.price)}</b></h5>
                    <h6> + VAT + Shipping (EU € 50.00, World € 120.00)</h6>
                    <h6>The completion of the instrument may take up to three weeks.</h6>
                </Col>
                <Col md={6} className="mb-4 d-flex flex-column align-items-stretch justify-content-center">
                    <Button className="mt-4"
                            variant="primary"
                            size="lg"
                            onClick={order}>
                        <b>Order</b>
                    </Button>
                </Col>
            </Row>
        }
    />;
}

