import React, {useCallback, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";

import {videos} from "content/reviews";
import {YoutubePlaceholder} from "components/styles";
import {MetaInfo} from "components/meta-info";
import {YouTube} from "./youtube";
import {synths} from "content/synths";
import {BasePage} from "../base-page";

export const Videos = () => {
    const [width, setWidth] = useState<number | undefined>(undefined);

    const colRef = useCallback((ref: HTMLDivElement) => {
        if (!ref || width) {
            return null;
        }
        setWidth(ref.getBoundingClientRect().width);
    }, [width]);

    return <BasePage>
        <MetaInfo
            title="Adam Synths - Videos - Handcrafted in Valencia, Spain"
            description="Adam Synths - Videos - Handcrafted in Valencia, Spain"
            image={synths[0].bigImage}
            imageW={1920}
            imageH={1080}
            path="/videos"
        />
        <Container className="review-container">
            <Row>
                {!width ?
                    <Col md={4} xs={12}>
                        <YoutubePlaceholder ref={colRef}/>
                    </Col>
                    :
                    videos.map((v, index) =>
                        <Col className="mt-4" md={4} xs={12} key={v.link}>
                            <YouTube id={v.link} width={width}/>
                        </Col>
                    )}
            </Row>
        </Container>
    </BasePage>
}
