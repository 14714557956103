import * as React from 'react';
import {Html} from '@react-email/html';
import {Markdown} from "@react-email/markdown";

import {UserOrder} from "state/order-type";
import {isEU} from "content/countries";
import {getVat, totalCalc} from "utils/total-calc";
import {ModuleInfo, modules} from "content/modules";
import {SynthInfo, synths} from "content/synths";
import {formatPrice} from "utils/format-price";
import {UserDataRenderer} from "components/user-data-renderer";

type Props = {
    order: UserOrder;
}

const EmailFN = ({order}: Props) => {
    const {
        firstName,
        lastName,
        country,
        synths: synthsQuantity,
        modules: modulesQuantity,
    } = order;

    const moduleTotal = totalCalc(
        modulesQuantity,
        (model) => modules.find(m => m.model === model)?.price
    );

    const synthTotal = totalCalc(
        synthsQuantity,
        (model) => synths.find(m => m.model === model)?.price
    );

    const total = moduleTotal + synthTotal;
    const vat = getVat(order);
    const shipping = total > 0 ? (isEU(country) ? 50 : 120) : 0;
    const filter = (({model}: SynthInfo | ModuleInfo) => (synthsQuantity[model] > 0 || modulesQuantity[model] > 0))

    return (
        <Html lang="en">
            <Markdown children={`## New Order from ${firstName} ${lastName}`}/>
            <table>
                <Markdown children={`### Order Details`}/>
                {synths.filter(filter).map(s => <tr key={s.model}>
                    <td><b>{s.model}:</b></td>
                    <td>{synthsQuantity[s.model]}</td>
                </tr>)}

                {modules.filter(filter).map(s => <tr key={s.model}>
                    <td><b>{s.model}:</b></td>
                    <td>{modulesQuantity[s.model]}</td>
                </tr>)}

                <tr>
                    <td><b>Subtotal:</b></td>
                    <td>{formatPrice(total)}</td>
                </tr>
                <tr>
                    <td><b>VAT ({vat * 100}%)</b></td>
                    <td>{formatPrice(vat * total)}</td>
                </tr>
                <tr>
                    <td><b>Shipping:</b></td>
                    <td>{formatPrice(shipping)}</td>
                </tr>
                <tr>
                    <td><b>Total:</b></td>
                    <td>{formatPrice(total + vat * total + shipping)}</td>
                </tr>
            </table>

            <Markdown children={`### User Details`}/>
            <table>
                {<UserDataRenderer
                    order={order}
                    Row={(children) => <tr>{children}</tr>}
                    Col1={(children) => <td key="key">{children}</td>}
                    Col2={(children) => <td key="value">{children}</td>}
                />}
            </table>
        </Html>
    );
};

export const InquiryEmail = React.memo<Props>(EmailFN);
