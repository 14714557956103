import {Link, useLocation} from "react-router-dom";
import {Container, Nav, Navbar} from "react-bootstrap";
import {googleLogout} from "@react-oauth/google";
import React, {useCallback, useState} from "react";

import "styles/navbar-toggler.scss";
import {adminRoutes} from "app";
import {logoutAction, useAdmin} from "state/admin";

export const AdminTopBar = () => {
    const {adminState, dispatch} = useAdmin();

    const location = useLocation();
    const [expanded, setExpanded] = useState(false);
    const currentPath = location.pathname;
    const logOut = useCallback(() => {
        googleLogout();
        dispatch(logoutAction());
    }, [dispatch]);

    return (
        <Navbar expanded={expanded} collapseOnSelect expand="lg" variant="dark" className="navbar-custom " fixed="top">
            <Container className="justify-content-between">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)}>
                        <span className={`navbar-toggler ${expanded ? 'open' : ''}`}>
                            <span className="toggler-icon top-bar"></span>
                            <span className="toggler-icon middle-bar"></span>
                            <span className="toggler-icon bottom-bar"></span>
                        </span>
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        {adminRoutes.filter(r => r.name).map(r =>
                            <Nav.Link
                                key={r.path}
                                as={Link}
                                to={r.path}
                                aria-label={r.name}
                                className={r.path === currentPath ? "active" : ""}>
                                {r.name}
                            </Nav.Link>
                        )}
                    </Nav>
                    <Nav>
                        <Nav.Link onClick={logOut} target="_blank">
                            Logout as {adminState?.profile?.email}
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};
