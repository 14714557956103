import React, {useState} from 'react';
import {animated, useTransition} from "@react-spring/web";
import styled from "styled-components";

type Props = {
    pageNumber: number;
    children: any
}

const Container = styled(animated.div)`
  position: relative;
  width: 100%;
  height: 100%;
`

const Content = styled(animated.div)`
  top: 0;
  bottom: 0;
  width: 100%;
`

export const PagedView = ({pageNumber, children}: Props) => {
    const [immediate, setImmediate] = useState(true);

    const transitions = useTransition(pageNumber, {
        from: {opacity: 0, transform: `translateX(100%)`},
        enter: {opacity: 1, transform: "translateX(0%)"},
        leave: {opacity: 0, transform: `translateX(-100%)`},
        immediate: immediate,
        onRest: () => setImmediate(false)
    });

    return (
        <Container>
            {transitions((style, item) =>
                <Content style={style}>
                    {children[item]}
                </Content>)}
        </Container>

    );
};

