import React, {createContext, useState, useContext, useEffect, useCallback} from 'react';

type ContextProp = {
    isAccepted: boolean;
    accept:() => void;
}

const GdprContext = createContext<ContextProp>({isAccepted: false, accept: () => {}});
const key = 'gdpr';

export const useGdpr = () => useContext(GdprContext);

type Props = {
    children: React.ReactNode;
}

export const GdprProvider = ({children}: Props) => {
    const [isAccepted, setIsAccepted] = useState<boolean>(() => {
        const storedBoolean = localStorage.getItem(key);
        return storedBoolean === 'true';
    });

    useEffect(() => {
        localStorage.setItem(key, isAccepted ? 'true' : 'false');
    }, [isAccepted]);

    const accept = useCallback(() => {
        setIsAccepted(true);
    }, [setIsAccepted]);

    return (
        <GdprContext.Provider value={{accept, isAccepted}}>
            {children}
        </GdprContext.Provider>
    );
};
