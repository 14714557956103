import React, {useEffect} from "react";
import {Analytics} from "@vercel/analytics/react"

import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Home} from "./pages/home/home";
import {Modules} from "./pages/modules/modules";
import {Videos} from "./pages/review/videos";
import {Order} from "./pages/order/order";
import {Contact} from "./pages/contact/contact";
import {animationDelayGenerator} from "./utils/animation-delay";
import Root from "./root";
import {ModuleDetail} from "./pages/modules/module-detail";
import {UnitProvider} from "./state/unit";
import {modules} from "./content/modules";
import {OrderProvider} from "./state/order";
import {ContactProvider} from "./state/contact";
import {TermsAndConditions} from "./pages/terms-and-conditions";
import {PrivacyPolicy} from "./pages/privacy-policy";
import {GdprProvider} from "./state/gdpr";
import {AdminOrders} from "./pages/admin/orders/admin-orders";
import {AdminUsers} from "./pages/admin/users/admin-users";
import {AdminProvider} from "./state/admin";
import {AdminUserDetails} from "./pages/admin/users/admin-user-detail";
import {RecallEditor} from "./pages/recall-editor";

export const routes = [
    {
        path: "/",
        element: <Home/>,
        name: "Home"
    },
    {
        path: "/modules",
        element: <Modules/>,
        name: "Modules",
    },
    ...modules.map(m => ({
        path: `modules/${m.model}`,
        element: <ModuleDetail key={m.model} model={m.model}/>,
        name: ""
    })),
    {
        path: "/videos",
        element: <Videos/>,
        name: "Videos",
    },
    {
        path: "/order",
        element: <Order/>,
        name: "Order",
    },
    {
        path: "/contact",
        element: <Contact/>,
        name: "Contact",
    },
    {
        path: "/recall",
        element: <RecallEditor/>,
        name: "Recall",
    },
    {
        path: "/terms-and-conditions",
        element: <TermsAndConditions/>,
    },
    {
        path: "/privacy-policy",
        element: <PrivacyPolicy/>,
    },
];

export const adminRoutes = [
    {
        path: "/admin",
        element: <AdminUsers/>,
    },
    {
        path: "/admin/users",
        element: <AdminUsers/>,
        name: "Users"
    },
    {
        path: `/admin/users/:id`,
        element: <AdminUserDetails/>,
        name: ""
    },
    {
        path: "/admin/orders",
        element: <AdminOrders/>,
        name: "Orders"
    },
]

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        children: routes
    },
    {
        path: "/admin",
        element: <Root/>,
        children: adminRoutes
    }
]);

const gen = animationDelayGenerator({});
export var nextDelay = () => gen.next().value;
export const resetNextDelay = () => nextDelay = () => {
};

export const App = () => {
    useEffect(resetNextDelay, []);
    return (
        <GdprProvider>
            <Analytics/>
            <AdminProvider>
                <ContactProvider>
                    <OrderProvider>
                        <UnitProvider>
                            <RouterProvider router={router}/>
                        </UnitProvider>
                    </OrderProvider>
                </ContactProvider>
            </AdminProvider>
        </GdprProvider>

    );
};

