import React, {useEffect} from "react";
import styled from "styled-components";

import {OrderStep} from "./order";
import {useDelayedFadeIn} from "utils/animation-delay";
import {FadeIn} from "components/fade-in";
import {resetOrderItemsAction, useOrder} from "state/order";

type Props = {
    setOrderStep: (orderStep: OrderStep) => void;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 85vh;
`;

const Content = styled.div`
  margin-top: 120px;
  max-width: 320px;
`;

export const Title = styled.h1`
  font-weight: bold;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  text-shadow: 1px 1px 10px #ff3d01;
  text-align: center;
`

export const OrderSuccess = ({setOrderStep}: Props) => {
    const {nextDelay} = useDelayedFadeIn({});
    const {dispatch} = useOrder();

    useEffect(() => {
        dispatch(resetOrderItemsAction());
    }, [dispatch])

    return <Container>
        <Content>
            <Title>
                <FadeIn nextDelay={nextDelay}>
                    Thank you!
                </FadeIn>
            </Title>
            <h5 className="mt-5">
                <FadeIn nextDelay={nextDelay}>
                    Please allow us approximately 24 hours to handle the request and respond with a confirmation of your
                    order.
                </FadeIn>
            </h5>
        </Content>
    </Container>;
}
