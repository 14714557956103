import React from "react";
import {Col, Row} from "react-bootstrap";

import {useOrder} from "state/order";
import {UserDataRenderer} from "components/user-data-renderer";

export const UserDataSummary = () => {
    const {orderState} = useOrder();

    return <>
        <div className="desktop-only">
            <UserDataRenderer
                order={orderState}
                Row={(children) => <Row className="mt-1">{children}</Row>}
                Col1={(children) => <Col key="key" md={2}>{children}</Col>}
                Col2={(children) => <Col key="value" md={10}>{children}</Col>}
            />
        </div>
        <div className="mobile-only">
            <UserDataRenderer
                order={orderState}
                Row={(children) => <Row className="mt-1">{children}</Row>}
                Col1={(children) => <Col key="key">{children}</Col>}
                Col2={(children) => <Col key="value">{children}</Col>}
            />
        </div>
    </>;

}

