import React from 'react';
import {Badge} from "content/modules";
import analog from "images/badges/badge-analog.png"
import firmware from "images/badges/badge-firmware.png"
import hq from "images/badges/badge-hq.png"
import mixed from "images/badges/badge-mixed.png"
import dac from "images/badges/badge-dac.png"
import floating from "images/badges/badge-floating.png"
import midi from "images/badges/badge-midi.png"
import usbMidi from "images/badges/badge-usbMidi.png"
import passive from "images/badges/badge-passive.png"
import {OneBadge} from "./styles";
import {FadeIn} from "./fade-in";
import styled from "styled-components";

type Props = { badges: Array<Badge>, nextDelay: () => object };

const badgeMap = new Map<Badge, string>([
    [Badge.analog, analog],
    [Badge.firmware, firmware],
    [Badge.hq, hq],
    [Badge.mixed, mixed],
    [Badge.dac, dac],
    [Badge.floating, floating],
    [Badge.midi, midi],
    [Badge.usbMidi, usbMidi],
    [Badge.passive, passive],
]);

export const Wrapper = styled.div`
  width: 44px;
`

export const Badges = ({badges, nextDelay}: Props) =>
    <div className="d-flex flex-row my-4">
        {badges.map(p => <Wrapper key={p}>
            <FadeIn  nextDelay={nextDelay}>
                <OneBadge src={badgeMap.get(p)}/>
            </FadeIn>
        </Wrapper>)}
    </div>;
