import React from "react";

import {useDelayedFadeIn} from "utils/animation-delay";
import {FadeIn} from "components/fade-in";
import {CrossFadeImages} from "components/crossfade-images";
import {Link} from "react-router-dom";
import {AlternateRow} from "components/alternate-row";
import {primaImages} from "../../constants";
import {MarkdownRenderer} from "utils/markdown-renderer";
import {SynthInfo} from "content/synths";
import {GetVisible} from "components/get-visible";

type Props = {
    fadeDelay: number;
    isRight: boolean;
    synth: SynthInfo;
}

const StaticCheckSynth = ({synth: {description}, isRight}: Props) => {
    const content1 = <CrossFadeImages images={primaImages}/>

    const content2 = <>
        <h4>Looking for all-in-one solution?</h4>
        <MarkdownRenderer markdown={description[0].description}/>
        <div className="d-flex justify-content-between align-items-center align-self-center">
            <div>
                <b>More &nbsp; ➤</b>
            </div>
        </div>

    </>;

    return <Link to={`/`}>
        <AlternateRow content1={content1} content2={content2} isRight={isRight}/>
    </Link>;
};

const AnimatedCheckSynth = ({synth: {description}, fadeDelay, isRight}: Props) => {
    const {nextDelay} = useDelayedFadeIn({start: fadeDelay});

    const content1 = <FadeIn nextDelay={nextDelay}>
        <CrossFadeImages images={primaImages}/>
    </FadeIn>;

    const content2 = <>
        <FadeIn nextDelay={nextDelay}>
            <h4>Looking for all-in-one solution?</h4>
        </FadeIn>
        <FadeIn nextDelay={nextDelay}>
            <MarkdownRenderer markdown={description[0].description}/>
        </FadeIn>
        <div className="d-flex justify-content-between align-items-center align-self-center">
            <div>
                <FadeIn nextDelay={nextDelay}>
                    <b>More &nbsp; ➤</b>
                </FadeIn>
            </div>
        </div>

    </>;

    return <Link to={`/`}>
        <AlternateRow content1={content1} content2={content2} isRight={isRight}/>
    </Link>;
};

export const CheckSynth = ({fadeDelay, isRight, synth}: Props) =>
    <GetVisible key="prima"
                builder={(visible, immediately) => !visible ?
                    <StaticCheckSynth synth={synth} isRight={isRight} fadeDelay={fadeDelay}/> :
                    <AnimatedCheckSynth
                        synth={synth}
                        fadeDelay={immediately ? fadeDelay : 0}
                        isRight={isRight}/>
                }/>;

