import React, {createContext, Dispatch, ReactNode, useContext, useEffect, useReducer} from "react";
import {EmptyUser, User} from "./order-type";

export type AdminCredentials = {
    access_token: string;
    token_type: string;
    expires_in: number;
    scope: string;
    prompt: string;
    created_at: number;
};

export type UserProfile = {
    id: string;
    name: string;
    given_name: string;
    family_name: string;
    email: string;
    picture: string;
}

export type OrderRequest = { type: string; field: string; };
export type FieldOrder = {
    field: string;
    value: boolean;
};

export type AdminSettings = {
    order: {
        [key: string]: FieldOrder;
    }
    showDeleted: { [key: string]: boolean; }
}

export type AdminState = {
    credentials: AdminCredentials | null;
    profile: UserProfile | null;
    authorized: boolean;
    adminSettings: AdminSettings;
    users: User[] | null;
    isProgress: boolean;
}

const EmptyAdminState = {
    credentials: null,
    profile: null,
    authorized: false,
    adminSettings: {order: {}, showDeleted: {}},
    users: null,
    isProgress: false
}

const STORE_CREDENTIALS_ACTION: string = 'STORE_CREDENTIALS_ACTION';
const STORE_PROFILE_ACTION: string = 'STORE_PROFILE_ACTION';
const LOGOUT_ACTION: string = 'LOGOUT_ACTION';
const CLEAR_USERS_ACTION: string = 'CLEAR_USERS_ACTION';
const AUTHORIZE_ACTION: string = 'AUTHORIZE_ACTION';
const CHANGE_SORT_ACTION: string = 'CHANGE_SORT_ACTION';
const SET_USERS_ACTION: string = 'SET_USERS_ACTION';
const START_PROGRESS_ACTION: string = 'START_PROGRESS_ACTION';
const STOP_PROGRESS_ACTION: string = 'STOP_PROGRESS_ACTION';

type StoreAdminAction = {
    type: typeof STORE_CREDENTIALS_ACTION;
    payload: AdminCredentials;
};

type StoreProfileAction = {
    type: typeof STORE_PROFILE_ACTION;
    payload: UserProfile
};

type LogoutAction = {
    type: typeof LOGOUT_ACTION;
};

type ClearUsersAction = {
    type: typeof CLEAR_USERS_ACTION;
};

type AuthorizeAction = {
    type: typeof AUTHORIZE_ACTION;
};

type ChangeSortAction = {
    type: typeof CHANGE_SORT_ACTION;
    payload: OrderRequest
};

type SetUsersAction = {
    type: typeof SET_USERS_ACTION;
    payload: User[];
};

type StartProgressAction = {
    type: typeof START_PROGRESS_ACTION;
}

type StopProgressAction = {
    type: typeof STOP_PROGRESS_ACTION;
}

export const storeCredentialsAction = (payload: AdminCredentials): StoreAdminAction =>
    ({type: STORE_CREDENTIALS_ACTION, payload});

export const storeProfileAction = (payload: UserProfile): StoreProfileAction =>
    ({type: STORE_PROFILE_ACTION, payload});
export const authorizeAction = (): AuthorizeAction => ({type: AUTHORIZE_ACTION});
export const logoutAction = (): LogoutAction => ({type: LOGOUT_ACTION});
export const clearUsersAction = (): ClearUsersAction => ({type: CLEAR_USERS_ACTION});
export const changeSortAction = (payload: OrderRequest): ChangeSortAction => ({type: CHANGE_SORT_ACTION, payload});
export const setUsersAction = (payload: User[]): SetUsersAction => ({type: SET_USERS_ACTION, payload});
export const startProgressAction = (): StartProgressAction => ({type: START_PROGRESS_ACTION});
export const stopProgressAction = (): StopProgressAction => ({type: STOP_PROGRESS_ACTION});

type AdminAction = StoreAdminAction |
    StoreProfileAction |
    LogoutAction |
    AuthorizeAction |
    SetUsersAction |
    ClearUsersAction |
    StartProgressAction |
    ChangeSortAction;

const adminReducer = (state: AdminState, action: AdminAction): AdminState => {
    switch (action.type) {
        case STORE_CREDENTIALS_ACTION:
            const storeAdminAction = action as StoreAdminAction;
            return {...state, credentials: {...storeAdminAction.payload}};
        case STORE_PROFILE_ACTION:
            const storeProfileAction = action as StoreProfileAction;
            return {...state, profile: {...storeProfileAction.payload}};
        case CHANGE_SORT_ACTION:
            const changeSortAction = action as ChangeSortAction;
            const payload = changeSortAction.payload as OrderRequest;
            const typeOrder = state.adminSettings?.order ?? {};
            let newOrder = {...typeOrder};
            newOrder[payload.type] = {field: payload.field, value: !typeOrder[payload.type]?.value};
            return {...state, adminSettings: {...state.adminSettings, order: {...newOrder}}};
        case AUTHORIZE_ACTION:
            return {...state, authorized: true};
        case CLEAR_USERS_ACTION:
            return {...state, users: null};
        case SET_USERS_ACTION:
            const setUserAction = action as SetUsersAction;
            const users = setUserAction.payload as User[];
            return {...state, users};
        case LOGOUT_ACTION:
            return {...state, credentials: null, profile: null, authorized: false};
        case START_PROGRESS_ACTION:
            return {...state, isProgress: true};
        case STOP_PROGRESS_ACTION:
            return {...state, isProgress: false};
        default:
            return state;
    }
};

interface AdminContextType {
    adminState: AdminState;
    dispatch: Dispatch<AdminAction>;
}

type Props = {
    children: ReactNode;
};

const persistKey = 'admin';
const item = localStorage.getItem(persistKey);
const storedAdmin = item ? JSON.parse(item) : EmptyAdminState ?? EmptyAdminState;
const AdminContext = createContext<AdminContextType>({...storedAdmin, authorized: false});

export const purgeAdmin = () => localStorage.removeItem(persistKey)

export const AdminProvider = ({children}: Props) => {
    const [adminState, dispatch] = useReducer(adminReducer, storedAdmin);

    useEffect(() => {
        localStorage.setItem(persistKey, JSON.stringify({...adminState, users: null}));
    }, [adminState]);

    const value = {
        adminState,
        dispatch,
    };

    return (
        <AdminContext.Provider value={value}>
            {children}
        </AdminContext.Provider>
    );
};

export const useAdmin = (): AdminContextType => {
    const context = useContext(AdminContext);
    if (!context) {
        throw new Error('useUser must be used within a AdminProvider');
    }
    return context;
};

export const useOrderAdmin = (type: string, defaultField: string) => {
    const {adminState, dispatch} = useAdmin();

    const order = adminState.adminSettings?.order[type] ?? {field: defaultField, value: false}
    const toggleOrder = (type: string, field: string) => {
        dispatch(changeSortAction({type, field}));
    };

    return {order, toggleOrder};
};

export const useUserAdmin = (id?: string) => {
    const {adminState} = useAdmin();
    const user = (adminState.users ?? []).find(u => u.id === id) ?? EmptyUser;
    return {user};
};
