import React from "react";

export type Fader = () => ({});

type AnimationProps = {
    start?: number;
    step?: number;
    slide?: boolean
    duration?: number;
}

export function* animationDelayGenerator({
                                             start = 0,
                                             step = 0.08,
                                             duration = 1000
                                         }: AnimationProps): Generator<React.CSSProperties> {
    let current = start;
    while (true) {
        yield {
            animationDelay: `${current}s`, animationName: `fadeIn`,
            animationDuration: `${duration}ms`,
            animationFillMode: 'both',
            animationTimingFunction: 'ease-out'
        };
        current += step;
    }
}

export function* delayGenerator({start = 0, step = 50}: AnimationProps): Generator<number> {
    let current = start;
    while (true) {
        yield current
        current += step;
    }
}

export function useDelayedFadeIn(props: AnimationProps) {
    const delayGenerator = animationDelayGenerator(props);
    const nextDelay = () => delayGenerator.next().value;
    return {nextDelay};
}


export function useDelay(props: AnimationProps) {
    const gen = delayGenerator(props);
    const nextDelay = () => gen.next().value;
    return {nextDelay};
}

