import React from "react";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

import {FadeIn} from "components/fade-in";
import {GetVisible} from "components/get-visible";
import {ModuleInfo} from "content/modules";
import {ProgressiveImage} from "components/progressive-image";
import {useUnit} from "state/unit";
import {convertUnit} from "utils/mm-to-inches";
import {useDelayedFadeIn} from "utils/animation-delay";

type Props = {
    module: ModuleInfo;
}

export const Features = ({module: {title, specs, block, manual, recall}}: Props) => {
    const {unit, toggleUnit} = useUnit();
    const {nextDelay} = useDelayedFadeIn({});

    return <GetVisible
        fader={nextDelay}
        builder={(visible, immediately, nd) =>
            <Row className="d-flex align-items-start justify-content-start">
                <Col md={6} className="mt-4">
                    <FadeIn nextDelay={nd}>
                        <Row className="justify-content-between">
                            <Col md={12} className="d-flex align-items-center justify-content-between">
                                <h4>Features</h4>
                                <h6 onClick={(_) => toggleUnit()}>{`${unit}`}</h6>
                            </Col>
                        </Row>
                    </FadeIn>
                    {Object.entries(specs).map(([key, value], index) =>
                        <Row key={key} className="justify-content-between py-2">
                            <Col className="d-flex align-items-center justify-content-start">
                                <FadeIn nextDelay={nd}>
                                    <b>{key}:&nbsp;&nbsp;</b>
                                </FadeIn>
                                <FadeIn nextDelay={nd}>
                                    {convertUnit(value, unit)}
                                </FadeIn>
                            </Col>
                        </Row>
                    )}

                    <Row className="mt-4">
                        <FadeIn nextDelay={nd}>
                            <Link to={recall} target="_blank">
                                <h5>➤ Download Recall sheet</h5>
                            </Link>
                        </FadeIn>
                    </Row>

                </Col>
                <Col md={6} className="mt-4">
                    <FadeIn nextDelay={nd}>
                        <h4>Manual</h4>
                    </FadeIn>
                    <FadeIn nextDelay={nd}>
                        <Link to={manual} target="_blank">
                            <ProgressiveImage src={block} title={`${title} Manual`} aspectRatio={1024 / 723}/>
                        </Link>
                    </FadeIn>
                </Col>
            </Row>}
    />;
}

