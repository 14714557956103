import React, {useCallback, useEffect, useRef} from "react";
import {Spinner} from "react-bootstrap";
import {render} from "@react-email/render";
import styled from "styled-components";

import {useDelayedFadeIn} from "utils/animation-delay";
import {ContactStep} from "./contact";
import {ContactEmail} from "./contact-email";
import {FadeIn} from "components/fade-in";
import {baseURL} from "utils/base-url";
import {useContact} from "state/contact";

type Props = {
    setContactStep: (con: ContactStep) => void;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-direction: column;
  height: 85vh;
`

export const ContactSending = ({setContactStep}: Props) => {
    const {nextDelay} = useDelayedFadeIn({});
    const {contactState} = useContact();
    const effectRan = useRef(false);

    const postInquiry = useCallback(async () => {
        const html = render(<ContactEmail contact={contactState}/>)
        const response = await fetch(`${baseURL()}/api/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({html, name: `${contactState.firstName} ${contactState.lastName}`}),
        });
        return response.json();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!effectRan.current) {
            postInquiry().then(() => setContactStep(ContactStep.success));
        }

        return () => {
            effectRan.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <FadeIn nextDelay={nextDelay}>
        <Container>
            <Spinner animation="border"/>
        </Container>
    </FadeIn>;
}
