import React, {useCallback, useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

import {countries, Country, State} from "content/countries";
import {User} from "state/order-type";
import {adminApi} from "api/admin-api";
import {startProgressAction, useAdmin} from "state/admin";
import {v4} from "uuid";

type Props = {
    user: User;
}

export const AdminUserDetailBody = ({user}: Props) => {
    const [localUser, setLocalUser] = useState({...user});
    const [showModal, setShowModal] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const {adminState, dispatch} = useAdmin();

    const navigate = useNavigate();
    const token = adminState.credentials?.access_token;

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setLocalUser({...localUser, [name]: value});
    }, [localUser]);

    const handleDelete = useCallback(async () => {
        setShowModal(false);
        if (!token) {
            return;
        }
        dispatch(startProgressAction());
        await adminApi.deleteUser(token, user.id!);
        navigate('/admin/users')
    }, [dispatch, navigate, user.id, token]);

    const handleSave = useCallback(async () => {
        if (!token) {
            return;
        }
        dispatch(startProgressAction());
        if (user.id) {
            await adminApi.updateUsers(token, localUser);
        } else {
            await adminApi.addUser(token, {...localUser, id: v4()});
        }
        navigate('/admin/users');
    }, [localUser, token, dispatch, navigate, user.id]);

    useEffect(() => {
        setSaveEnabled(JSON.stringify(localUser) !== JSON.stringify(user));
    }, [user, localUser]);

    const states = countries.find(c => c.code2 === user.country)?.states || [];

    return <>
        <Row>
            <Col md={6} className="mt-4">
                <Form.Group controlId="formFirstName">
                    <Form.Label className="required">
                        First Name
                    </Form.Label>
                    <Form.Control type="text"
                                  name="firstName"
                                  value={localUser.firstName}
                                  onChange={handleChange}
                                  required
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide your first name.
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col md={6} className="mt-4">
                <Form.Group controlId="formLastName">
                    <Form.Label className="required">
                        Last Name
                    </Form.Label>
                    <Form.Control type="text"
                                  name="lastName"
                                  value={localUser.lastName}
                                  onChange={handleChange}
                                  required
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide your last name.
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col md={6} className="mt-4">
                <Form.Group controlId="formEmail">
                    <Form.Label className="required">
                        Email
                    </Form.Label>
                    <Form.Control type="text"
                                  name="email"
                                  value={localUser.email}
                                  onChange={handleChange}
                                  required
                                  pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid email address.
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col md={6} className="mt-4">
                <Form.Group controlId="formCountry">
                    <Form.Label className="required">
                        Country
                    </Form.Label>
                    <Form.Control
                        name="country"
                        as="select"
                        value={localUser.country}
                        onChange={handleChange}>
                        <option value="">Choose one...</option>
                        {countries.map((c: Country) =>
                            <option key={c.code2}
                                    value={c.code2}>
                                {c.name}
                            </option>)}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        Please provide your address.
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
        {states.length > 0 ?
            <Form.Group className="mt-4" controlId="formState">
                <Form.Label className="required">
                    State/Region
                </Form.Label>
                <Form.Control
                    name="state"
                    as="select"
                    disabled={states.length === 0}
                    value={localUser.state}
                    required
                    onChange={handleChange}>
                    <option value="">Choose one...</option>
                    {states.map((s: State) =>
                        <option key={s.code}
                                value={s.code}>
                            {s.name}
                        </option>)}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please provide your address.
                </Form.Control.Feedback>
            </Form.Group> : null}
        <Form.Group className="mt-4" controlId="formAddressLine1">
            <Form.Label className="required">
                Address Line 1
            </Form.Label>
            <Form.Control type="text"
                          name="addressLine1"
                          value={localUser.addressLine1}
                          onChange={handleChange}
                          required
            />
            <Form.Control.Feedback type="invalid">
                Please provide your address details.
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mt-4" controlId="formAddressLine2">
            <Form.Label>
                Address Line 2
            </Form.Label>
            <Form.Control type="text"
                          name="addressLine2"
                          value={localUser.addressLine2}
                          onChange={handleChange}
            />
        </Form.Group>
        <Row>
            <Col md={6} className="mt-4">
                <Form.Group controlId="formPostalCode">
                    <Form.Label className="required">
                        Postal Code
                    </Form.Label>
                    <Form.Control type="text"
                                  name="postalCode"
                                  value={localUser.postalCode}
                                  onChange={handleChange}
                                  required
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide your postal code.
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col md={6} className="mt-4">
                <Form.Group controlId="formCity">
                    <Form.Label className="required">
                        City
                    </Form.Label>
                    <Form.Control type="text"
                                  name="city"
                                  value={localUser.city}
                                  onChange={handleChange}
                                  required
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide your city.
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col className="mb-4 d-flex flex-column align-items-stretch justify-content-center">
                <Button variant="primary"
                        size="lg"
                        disabled={!saveEnabled}
                        onClick={handleSave}
                        type="submit">
                    <b>Save</b>
                </Button>
            </Col>
            <Col className="mb-4 d-flex flex-column align-items-stretch justify-content-center">
                {user.id ?
                    <Button variant="danger"
                            size="lg"
                            onClick={() => setShowModal(true)}
                            type="submit">
                        <b>Delete</b>
                    </Button> :
                    null
                }
            </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                <Button variant="danger" onClick={handleDelete}>Delete</Button>
            </Modal.Footer>
        </Modal>
    </>;
}

