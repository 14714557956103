import {Outlet, useLocation} from "react-router-dom";
import {useLayoutEffect} from "react";

export default function Root() {
    const location = useLocation();

    useLayoutEffect(() => {
        // @ts-ignore
        document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
    }, [location.pathname]);

    return (
        <>
            <Outlet />
        </>
    );
}
