import React, {memo, useCallback, useEffect, useState} from "react";
import {Button, Container, Row, Spinner} from "react-bootstrap";
import {useGoogleLogin} from "@react-oauth/google";

import {authorizeAction, logoutAction, storeCredentialsAction, storeProfileAction, useAdmin} from "state/admin";
import {Center} from "components/styles";
import {adminApi} from "api/admin-api";

type Props = {
    children: React.ReactNode;
}

const AuthCheckFn = ({children}: Props) => {
    const [initialized, setInitialized] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const {adminState, dispatch} = useAdmin();


    const login = useGoogleLogin({
        onSuccess: (codeResponse) => dispatch(storeCredentialsAction({
            ...codeResponse, created_at: Date.now()
        })),
        onError: (error) => console.log('Login Failed:', error)
    });

    const checkAuth = useCallback(async (token: string) => {
        const isAdmin = await adminApi.isAdmin(token);
        if (!isAdmin) {
            setError('You are not authorized to access this page!');
        } else {
            const profile = await adminApi.getProfile(token);
            dispatch(storeProfileAction(profile));
            dispatch(authorizeAction());
        }
        setInitialized(true);
    }, [dispatch]);

    useEffect(() => {
        const expiresIn = adminState.credentials?.expires_in ?? -1;
        const created = adminState.credentials?.created_at ?? -1;

        if (expiresIn > -1 && created - 1 && (expiresIn * 1000 + created) < Date.now()) {
            dispatch(logoutAction());
        }
    }, [dispatch, adminState.credentials?.created_at, adminState.credentials?.expires_in]);

    useEffect(() => {
        const token = adminState.credentials?.access_token;
        if (!token || adminState.authorized) {
            setInitialized(true);
        } else {
            checkAuth(token);
        }
    }, [adminState.credentials?.access_token, adminState.authorized, checkAuth]);


    if (!initialized) {
        return <Center>
            <Spinner animation="border"/>
        </Center>;
    }

    return !adminState.authorized ? (
        <Container className="d-flex flex-column flex-grow-1 align-items-center justify-content-center">
            <Row>
                <Button onClick={() => login()}>Login</Button>
            </Row>
            <Row>
                <div>{error}</div>
            </Row>
        </Container>
    ) : (
        <>
            {children}
        </>
    );
}

export const AuthCheck = memo<Props>(AuthCheckFn);
