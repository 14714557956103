import React, {createContext, Dispatch, ReactNode, useContext, useEffect, useReducer} from "react";
import {synths} from "content/synths";
import {modules} from "content/modules";
import {UserOrder, QuantityMap} from "./order-type";

const quantityReducer = (acc: QuantityMap, curr: string) => {
    acc[curr] = 0;
    return acc;
};

export const EmptyOrder = {
    paymentOption: '',
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    state: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    city: '',
    message: '',
    synths: synths.map(s => s.model).reduce(quantityReducer, {}),
    modules: modules.map(s => s.model).reduce(quantityReducer, {})
};

const UPDATE_ORDER_ACTION: string = 'UPDATE_ORDER_ACTION';
const CHANGE_QUANTITY_ACTION: string = 'CHANGE_QUANTITY_ACTION';
const RESET_ORDER_ITEM_ACTION: string = 'RESET_ORDER_ITEM_ACTION';

type UpdateOrderAction = {
    type: typeof UPDATE_ORDER_ACTION;
    payload: UserOrder;
};

type ResetOrderItemAction = {
    type: typeof RESET_ORDER_ITEM_ACTION;
};

type ChangeQuantityAction = {
    type: typeof CHANGE_QUANTITY_ACTION;
    payload: {
        model: string;
        isModule: boolean;
        quantity: number;
    };
};

export const updateOrderAction = (payload: UserOrder): UpdateOrderAction =>
    ({type: UPDATE_ORDER_ACTION, payload});

export const resetOrderItemsAction = (): ResetOrderItemAction =>
    ({type: RESET_ORDER_ITEM_ACTION});

export const changeQuantityAction = (model: string,
                                     isModule: boolean,
                                     quantity: number): ChangeQuantityAction =>
    ({
        type: CHANGE_QUANTITY_ACTION, payload: {
            model, isModule, quantity
        }
    });

type OrderAction = UpdateOrderAction | ChangeQuantityAction | ResetOrderItemAction;

const orderReducer = (state: UserOrder, action: OrderAction) => {
    switch (action.type) {
        case UPDATE_ORDER_ACTION:
            const updateAction = action as UpdateOrderAction;
            return {...state, ...updateAction.payload};
        case CHANGE_QUANTITY_ACTION:
            const {payload: {isModule, model, quantity}} = action as ChangeQuantityAction;
            const key = isModule ? 'modules' : 'synths';
            const currentQuantity = state[key][model];
            const currentMap = {...state[key]};
            return {...state, [key]: {...currentMap, [model]: currentQuantity + quantity}};
        case RESET_ORDER_ITEM_ACTION:
            return {
                ...state,
                synths: synths.map(s => s.model).reduce(quantityReducer, {}),
                modules: modules.map(s => s.model).reduce(quantityReducer, {})
            };
        default:
            return state;
    }
};

interface OrderContextType {
    orderState: UserOrder;
    dispatch: Dispatch<OrderAction>;
}

type Props = {
    children: ReactNode;
};

const persistKey = 'order';
const item = localStorage.getItem(persistKey);
const storedOrder = item ? JSON.parse(item) : EmptyOrder ?? EmptyOrder;
const OrderContext = createContext<OrderContextType>(storedOrder);

export const purgeOrder = () => localStorage.removeItem(persistKey)
export const OrderProvider = ({children}: Props) => {
    const [orderState, dispatch] = useReducer(orderReducer, storedOrder);

    useEffect(() => {
        localStorage.setItem(persistKey, JSON.stringify(orderState))
    }, [orderState]);

    const value = {
        orderState,
        dispatch,
    };

    return (
        <OrderContext.Provider value={value}>
            {children}
        </OrderContext.Provider>
    );
};

export const useOrder = (): OrderContextType => {
    const context = useContext(OrderContext);
    if (!context) {
        throw new Error('useOrder must be used within a OrderProvider');
    }
    return context;
};
