import styled from "styled-components";
import YouTube from "react-youtube";

export const InfoTop = styled.div`
  position: absolute;
  top: 0;
  margin: 0 1em;
  padding: 1em;
  background-color: transparent;
`

export const Relative = styled.div`
  position: relative;
  width: 100%;
`

export const Clickable = styled.div`
    cursor: pointer;
  width: 100%;
`

export const AspectContainer = styled.div<{ $aspectRatio: number }>`
  padding-top: ${props => `${(1 / props.$aspectRatio) * 100}%`};
  background-color: black;
  position: relative;
  overflow: hidden;
`;

export const OneBadge = styled.img`
  display: block;
  width: 44px;
  height: auto;
  object-fit: cover;
  margin-right: 8px;
`

export const MainTitle = styled.h1`
  font-weight: bold;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  text-shadow: 1px 1px 10px #ff3d00;
`

export const Description = styled.div`
  line-height: 1.4;
`
export const Address = styled.div`
  text-align: center;
  -webkit-user-select: text; /* Safari */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */
`

export const YouTubePlayer = styled(YouTube)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const Center = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const YoutubePlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BadgeWrapper = styled.div`
  position: relative;
  justify-content: center;
`

export const Badge = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  right: -8px;
  top: -4px;
  filter: blur(2px);
  animation: pulsate 2s infinite alternate;
  @keyframes pulsate {
    0% {
      transform: scale(1);
      opacity: 1;
      filter: blur(2px);
    }
    50% {
      transform: scale(1);
      opacity: 0.5;
      filter: blur(1px);
    }
    100% {
      transform: scale(1);
      opacity: 1;
      filter: blur(2px);
`
export const NavBadge = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  right: -4px;
  top: 20px;
  filter: blur(2px);
  animation: pulsate 2s infinite alternate;
  @keyframes pulsate {
    0% {
      transform: scale(1);
      opacity: 1;
      filter: blur(2px);
    }
    50% {
      transform: scale(1);
      opacity: 0.5;
      filter: blur(1px);
    }
    100% {
      transform: scale(1);
      opacity: 1;
      filter: blur(2px);
`
