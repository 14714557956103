import React from 'react';
import Markdown from "marked-react";

type Props = {
    markdown: string;
};

export const MarkdownRenderer = ({markdown}: Props) => <div className="description">
    <Markdown>{markdown}</Markdown>
</div>
