import React, {useCallback, useState} from "react";

import {Relative, YouTubePlayer} from "components/styles";
import {Placeholder} from "components/placeholder";
import {ErrorBoundary, withErrorHandling} from "components/error-boundary";

type Props = {
    id: string;
    width: number;
}

const WrappedYouTubePlayer = withErrorHandling(YouTubePlayer);

export const YouTube = ({id, width}: Props) => {
    const [ready, setReady] = useState(false);

    const height = width / (4 / 3.5);
    const opts = {
        width,
        height,
        playerVars:
            {
                autoplay: 0,
            }
    };

    const onReady = useCallback(() => {
        setTimeout(() => setReady(true), 1000);
    }, []);

    return <Relative>
        <div className={ready ? "fade-out-deep" : ""}>
            <Placeholder width={width} height={height}/>
        </div>
        <div className={ready ? "fade-in-deep" : "hidden"}>
            <ErrorBoundary>
                <WrappedYouTubePlayer videoId={id} opts={opts} onReady={onReady}/>
            </ErrorBoundary>
        </div>
    </Relative>
}
