import React from 'react';
import {Col, Row} from "react-bootstrap";

type Props = { content1: React.ReactNode, content2: React.ReactNode, isRight: boolean };

const SectionDesktop = ({content1, content2, isRight = false}: Props) =>
    <Row className="justify-content-between mb-5">
        {isRight ? <Col md={6} className="d-flex align-items-start">
            {content1}
        </Col> : null}
        <Col md={6} className="d-flex justify-content-center align-items-center">
            <div className={`one-section justify-content-center ${isRight ? "align-items-end" : "align-items-start"}`}>
                {content2}
            </div>
        </Col>
        {!isRight ? <Col md={6} className="d-flex justify-content-end">
            {content1}
        </Col> : null}
    </Row>;

const SectionMobile = ({content1, content2}: Props) =>
    <>
        <div className="one-section justify-content-center align-items-start">
            {content1}
        </div>
        <div className="justify-content-center mt-4  mb-5">
            {content2}
        </div>
    </>;

export const AlternateRow = (props: Props) =>
    <>
        <div className="desktop-only">
            <SectionDesktop {...props}/>
        </div>
        <div className="mobile-only">
            <SectionMobile {...props}/>
        </div>
    </>;
