export class VideoInfo {
    constructor(title: string, description: string, link: string) {
        this.title = title;
        this.description = description;
        this.link = link;
    }

    title: string;
    description: string;
    link: string;
}

export const videos: Array<VideoInfo> = [
    {
        title: "Dean Doughters video",
        description: "",
        link: "mSOJdC7t9tU"
    },
    {
        title: "Five patches 1 with AS Synths Prima",
        description: "",
        link: "HZtFw2E8kMc"
    },
    {
        title: "Five patches 2 with AS Synths Prima",
        description: "",
        link: "j4ff8v5w9Sk"
    },
    {
        title: "CLOUD FACTORY by AS Synthesizers",
        description: "",
        link: "IS5BPCR-xwk"
    },
    {
        title: "INTERSTELLAR by AS Synthesizers",
        description: "",
        link: "I7x58DhTgSw"
    },
    {
        title: "ZAKOO by AS Synthesizers",
        description: "",
        link: "e6Gjeuwtayw"
    },
    {
        title: "FAT FROG by AS Synthesizers",
        description: "",
        link: "VvqXo8U5Ycc"
    },
    {
        title: "Assembling the Steda SR-909",
        description: "",
        link: "VRAwXzM6TzY"
    },
    {
        title: "AS Synthesizers – Studio Jam",
        description: "",
        link: "zlQIRh2_ZRM"
    },
    {
        title: "Gainlab Audio Bishop – Synth Bass Sample",
        description: "",
        link: "zqjHdkH_Q0k"
    },
];
