import {baseURL} from "../utils/base-url";
import {User} from "../state/order-type";

export const adminApi = {
    getProfile: async (token: string) => {
        try {
            const profileResponse = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json'
                },
            });
            return await profileResponse.json();
        } catch (err) {
            console.log(`Failed to get profile : ${err}`);
        }
        return null;
    },
    isAdmin: async (token: string) => {
        try {
            const adminResponse = await fetch(`${baseURL()}/api/is-admin`, {
                headers: {
                    Authorization: `${token}`,
                    Accept: 'application/json'
                }
            });
            return await adminResponse.json();
        } catch (err) {
            console.log(`Failed to get profile : ${err}`);
        }
        return false;
    },
    getUsers: async (token: string) => {
        try {
            const queryParams = {
                type: 'user',
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const adminResponse = await fetch(`${baseURL()}/api/user-admin?${queryString}`, {
                method: 'GET',
                headers: {
                    Authorization: `${token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            return await adminResponse.json();
        } catch (err) {
            console.log(`Failed to get users : ${err}`);
        }
    },
    addUser: async (token: string, user: User) => {
        try {
            const adminResponse = await fetch(`${baseURL()}/api/user-admin`, {
                method: 'PUT',
                headers: {
                    Authorization: `${token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: user,
                    type: 'user'
                })
            });
            return await adminResponse.json();
        } catch (err) {
            console.log(`Failed to get users : ${err}`);
        }
    },
    updateUsers: async (token: string, user: User) => {
        try {
            const adminResponse = await fetch(`${baseURL()}/api/user-admin`, {
                method: 'POST',
                headers: {
                    Authorization: `${token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: user,
                    type: 'user'
                })
            });
            return await adminResponse.json();
        } catch (err) {
            console.log(`Failed to get users : ${err}`);
        }
    },
    deleteUser: async (token: string, id: string) => {
        try {
            const adminResponse = await fetch(`${baseURL()}/api/user-admin`, {
                method: 'DELETE',
                headers: {
                    Authorization: `${token}`,
                    Accept: 'application/json',
                },
                body: JSON.stringify({
                    data: {id},
                    type: 'user'
                })
            });
            return await adminResponse.json();
        } catch (err) {
            console.log(`Failed to get users : ${err}`);
        }
    }
}
