import React, {useMemo, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {Container, Nav, Navbar} from "react-bootstrap";

import logo from "images/as-logo.png";
import insta from "images/insta-logo.png";
import youtube from "images/youtube-logo.png";
import soundcloud from "images/soundcloud-logo.png";
import "styles/navbar-toggler.scss";
import {instagramLink, soundCloudLink, youtubeLink} from "utils/links";
import {nextDelay, routes} from "app";
import {Badge, BadgeWrapper, NavBadge} from "./styles";
import { useOrder} from "state/order";

function getCurrentPath(location: string) {
    if (location.length === 1) {
        return location;
    }
    const segments = location.split("/");
    return "/" + segments[1];
}

const hasSome = (items: { [key: string]: Object }) => Object.values(items).some(m => m > 0);

export const TopBar = () => {
    const location = useLocation();
    const [expanded, setExpanded] = useState(false);
    const currentPath = getCurrentPath(location.pathname);
    const {orderState: {synths, modules}} = useOrder();
    const renderBadge = useMemo(() =>
            currentPath !== "/order" && (hasSome(synths) || hasSome(modules)),
        [synths, modules, currentPath]);

    return (
        <Navbar expanded={expanded} collapseOnSelect expand="lg" variant="dark" className="navbar-custom " fixed="top">
            <Container className="justify-content-between">
                <Navbar.Brand as={Link} to="/" className="home">
                    <img src={logo} alt="Adam Synths" aria-label="Adam Synths" className="brand-name"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)}>
                    <BadgeWrapper>
                        <span className={`navbar-toggler ${expanded ? 'open' : ''}`}>
                            <span className="toggler-icon top-bar"></span>
                            <span className="toggler-icon middle-bar"></span>
                            <span className="toggler-icon bottom-bar"></span>
                        </span>
                        {!expanded && renderBadge ? <NavBadge/> : null}
                    </BadgeWrapper>
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        {routes.filter(r => r.name).map(r =>
                            <Nav.Link
                                key={r.path}
                                as={Link}
                                to={r.path}
                                aria-label={r.name}
                                style={nextDelay()}
                                className={r.path === currentPath ? "active" : ""}>
                                <BadgeWrapper>
                                    {r.name}
                                    {renderBadge && r.name === "Order" ? <Badge/> : null}
                                </BadgeWrapper>
                            </Nav.Link>
                        )}
                    </Nav>
                    <Nav>
                        <Nav.Link href={instagramLink} target="_blank" className="social-logo" style={nextDelay()}>
                            <img src={insta} alt="Instagram" aria-label="Instagram - TopMenu"/>
                        </Nav.Link>
                        <Nav.Link href={youtubeLink} target="_blank" className="social-logo" style={nextDelay()}>
                            <img src={youtube} alt="YouTube" aria-label="YouTube - TopMenu"/>
                        </Nav.Link>
                        <Nav.Link href={soundCloudLink} target="_blank" className="social-logo" style={nextDelay()}>
                            <img src={soundcloud} alt="SoundCloud" aria-label="SoundCloud - TopMenu"/>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};
