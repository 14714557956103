import React from 'react';

import {AspectContainer, Clickable} from "components/styles";
import {AlternateRow} from "components/alternate-row";
import {useDelayedFadeIn} from "utils/animation-delay";
import {FadeIn} from "components/fade-in";
import {Description} from "content/synths";
import {GetVisible} from "components/get-visible";
import {ProgressiveImage} from "components/progressive-image";
import {MarkdownRenderer} from "utils/markdown-renderer";

type Props = {
    description: Description,
    image: string,
    isRight?: boolean,
    fadeDelay: number,
    openGallery: () => void,
};

const StaticSynthRow = ({description: {title, description}}: Props) =>
    <AlternateRow
        content1={<AspectContainer $aspectRatio={640 / 360}/>}
        content2={
            <>
                <h4>{title}</h4>
                <MarkdownRenderer markdown={description} />
            </>
        } isRight/>;

const FadedSynthRow = ({description: {title, description}, image, isRight = false, openGallery, fadeDelay}: Props) => {
    const {nextDelay} = useDelayedFadeIn({start: fadeDelay});
    return <AlternateRow
        content1={
            <Clickable onClick={openGallery}>
                <FadeIn nextDelay={nextDelay}>
                    <ProgressiveImage src={image} aspectRatio={640 / 360} title={title}/>
                </FadeIn>
            </Clickable>
        }
        content2={
            <>
                <FadeIn nextDelay={nextDelay}>
                    <h4>{title}</h4>
                </FadeIn>
                <FadeIn nextDelay={nextDelay}>
                    <MarkdownRenderer markdown={description} />
                </FadeIn>
            </>
        } isRight={isRight}/>;
};

export const SynthRow = ({fadeDelay, ...props}: Props) => <GetVisible
    builder={(visible, immediately) => visible ?
        <FadedSynthRow {...props} fadeDelay={immediately ? fadeDelay : 0}/> :
        <StaticSynthRow {...props} fadeDelay={0}/>}/>;
