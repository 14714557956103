import React, {useCallback} from "react";

import {ModuleInfo} from "content/modules";
import {changeQuantityAction, useOrder} from "state/order";
import {OrderItem} from "./order-item";
import {maxQuantity} from "../../../constants";

type Props = {
    synth: ModuleInfo;
    readOnly: boolean;
}

export const ModuleOrderRow = ({synth: {title, images, model, price}, readOnly}: Props) => {
    const {orderState, dispatch} = useOrder();
    const quantity = orderState.modules[model];
    const minusEnabled = quantity > 0;
    const plusEnabled = quantity < maxQuantity;

    const increase = useCallback(() =>
        dispatch(changeQuantityAction(model, true, +1)), [model, dispatch]);

    const decrease = useCallback(() =>
        dispatch(changeQuantityAction(model, true, -1)), [model, dispatch]);

    return <OrderItem
        minusEnabled={minusEnabled}
        plusEnabled={plusEnabled}
        image={images[0]}
        title={title}
        model={model}
        price={price}
        decrease={decrease}
        quantity={quantity}
        increase={increase}
        isSynth={false}
        readOnly={readOnly}
    />;
};



