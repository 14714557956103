import React, { useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {UserData} from "./form/user-data";
import {Button, Col, Form, Row} from "react-bootstrap";
import {ItemList} from "./form/item-list";
import {MainTitle} from "components/styles";
import {OrderStep} from "./order";
import {useDelayedFadeIn} from "utils/animation-delay";
import {FadeIn} from "components/fade-in";

type Props = {
    setOrderStep: (orderStep: OrderStep) => void;
}

export const OrderForm = ({setOrderStep}: Props) => {
    const {nextDelay} = useDelayedFadeIn({});
    const [validated, setValidated] = useState(false);
    const [captcha, setCaptcha] = useState("");
    const handleCaptchaChange = (value: Object | null) => setCaptcha(value as string);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;
        setValidated(false);
        if (form.checkValidity()) {
            setOrderStep(OrderStep.summary);
        }
        setValidated(true);
    };

    return <>
        <MainTitle>Your Order</MainTitle>
        <Form noValidate validated={validated} className="mt-4" onSubmit={handleSubmit}>
            <FadeIn nextDelay={nextDelay}>
                <ItemList/>
            </FadeIn>
            <FadeIn nextDelay={nextDelay}>
                <h4 className="mt-5">Your Data</h4>
            </FadeIn>
            <FadeIn nextDelay={nextDelay}>
                <UserData/>
            </FadeIn>
            <Row className="mt-4 d-flex flex-row align-items-center justify-content-center">
                <Col md={6} className="mb-4 d-flex flex-row align-items-center justify-content-center">
                    <Form.Group controlId="formCaptcha">
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY ?? ""}
                            onChange={handleCaptchaChange}
                        />
                        <Form.Control
                            type="text"
                            name="captcha"
                            value={captcha}
                            onChange={() => {
                            }}
                            required
                            className="d-none"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please verify that you are not a robot.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6} className="mb-4 d-flex flex-column align-items-stretch justify-content-center">
                    <Button variant="primary"
                            size="lg"
                            type="submit">
                        <b>Go to summary</b>
                    </Button>
                </Col>
            </Row>

        </Form>
    </>
}
