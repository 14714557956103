import React, {createContext, Dispatch, ReactNode, useContext, useEffect, useReducer} from "react";

export type Contact = {
    firstName: string;
    lastName: string;
    email: string;
    message: string;
    source?: string;
    sourceOther?: string;
}

export const EmptyContact = {
    firstName: '',
    lastName: '',
    email: '',
    message: '',
};

const UPDATE_CONTACT_ACTION: string = 'UPDATE_CONTACT_ACTION';
const RESET_CONTACT_ACTION: string = 'RESET_CONTACT_ACTION';

type UpdateContactAction = {
    type: typeof UPDATE_CONTACT_ACTION;
    payload: Contact;
};

type ResetContactAction = {
    type: typeof RESET_CONTACT_ACTION;
};

export const updateContactAction = (payload: Contact): UpdateContactAction =>
    ({type: UPDATE_CONTACT_ACTION, payload});

export const resetContactAction = (): ResetContactAction => ({type: RESET_CONTACT_ACTION});

type ContactAction = UpdateContactAction | ResetContactAction;

const contactReducer = (state: Contact, action: ContactAction) => {
    switch (action.type) {
        case UPDATE_CONTACT_ACTION:
            const updateAction = action as UpdateContactAction;
            return {...state, ...updateAction.payload};
        case RESET_CONTACT_ACTION:
            return EmptyContact;
        default:
            return state;
    }
};

interface ContactContextType {
    contactState: Contact;
    dispatch: Dispatch<ContactAction>;
}

type Props = {
    children: ReactNode;
};

const persistKey = 'contact';
const item = localStorage.getItem(persistKey);
const storedContact = item ? JSON.parse(item) : EmptyContact ?? EmptyContact;
const ContactContext = createContext<ContactContextType>(storedContact);

export const purgeContact = () => localStorage.removeItem(persistKey)

export const ContactProvider = ({children}: Props) => {
    const [contactState, dispatch] = useReducer(contactReducer, storedContact);

    useEffect(() => {
        localStorage.setItem(persistKey, JSON.stringify(contactState))
    }, [contactState]);

    const value = {
        contactState,
        dispatch,
    };

    return (
        <ContactContext.Provider value={value}>
            {children}
        </ContactContext.Provider>
    );
};

export const useContact = (): ContactContextType => {
    const context = useContext(ContactContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};
