import styled from "styled-components";
import {Link} from "react-router-dom";

export const Label = styled.span`
  user-select: none;
`;

export const Title = styled(Link)<{$enabled: boolean}>`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  opacity: ${({$enabled}) => $enabled ? 1 : 0.5};
`;

export const Button = styled.div<{$enabled?: boolean}>`
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  color: ${({$enabled}) => $enabled ? "white" : "grey"};
`;
