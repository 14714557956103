import {
    AdminState,
    clearUsersAction,
    FieldOrder,
    logoutAction,
    setUsersAction,
    useAdmin,
    useOrderAdmin
} from "state/admin";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import React, {memo, useCallback, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Entity, User} from "../../state/order-type";
import {adminApi} from "../../api/admin-api";
import {AdminProgress} from "./admin-progress";
import {AdminBase} from "./admin-base";

export const entitySorter = (fo: FieldOrder) => (a: Object, b: Object) => {
    const order = fo.value ? 1 : -1;
    // @ts-ignore
    return order * a[fo.field].localeCompare(b[fo.field]);
};


type HeaderProps = {
    field: string;
    label: string;
}

const ColHeaderFactory = (orderKey: string, defaultFieldName: string) =>
    memo<HeaderProps>(({field, label}) => {
        const {order, toggleOrder} = useOrderAdmin(orderKey, defaultFieldName);
        return <Col onClick={() => toggleOrder(orderKey, field)}
                    className="bottom-border d-flex justify-content-start align-items-center">
            {order.field === field ? <b>{order.value ? '▲' : '▼'}</b> : null}
            <b>{label}</b>
        </Col>;
    })

const EntityRowFactory = (orderKey: string, fields: string[]) => <T extends Entity>({entities}: { entities: T[] }) =>
    <>
        {entities.map((entity, index) =>
            <Link key={entity.id} to={`/admin/${orderKey}/${entity.id}`}>
                <Row>
                    {fields.map((k) => {
                            // @ts-ignore
                            const value = entity[k];
                            return <Col md={3} key={k}
                                        className={`${index % 2 === 0 ? 'row-odd' : 'row-even'} d-flex justify-content-start align-items-center`}>
                                {value}
                            </Col>;
                        }
                    )}
                </Row>
            </Link>
        )}
    </>


const getEntity = (adminState: AdminState, key: string) => {
    if (key === "users") {
        return adminState.users;
    }
    return undefined;
};

const fetchEntities = async (token: string, key: string) => {
    if (key === "users") {
        return await adminApi.getUsers(token);
    }
    return [];
};

const getSetAction = <T extends any>(newEntities: T[], key: string) => {
    if (key === "users") {
        return setUsersAction(newEntities as User[]);
    }
    return undefined
};


type Props = {
    name: string,
    fields: { [key: string]: string }
}

export const AdminEntityList = <T extends Entity>({name, fields}: Props) => {
    const {adminState, dispatch} = useAdmin();
    const [rawEntities, setRawEntities] = useState<T[]>([]);
    const {order} = useOrderAdmin(name, Object.keys(fields)[0]);
    const [search, setSearch] = useState("");
    const navigate = useNavigate();

    const ColHeader = ColHeaderFactory(name, Object.keys(fields)[0]);
    const UserRows = EntityRowFactory(name, Object.keys(fields));


    const entities = getEntity(adminState, name);
    const token = adminState.credentials?.access_token;

    useEffect(() => {
        if (!token) {
            dispatch(logoutAction());
            return;
        } else {
            (async () => {
                dispatch(clearUsersAction());
                const entities = await fetchEntities(token, name);
                setRawEntities(entities);
            })();
        }

    }, [dispatch, name, token]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setSearch(value);
    }, []);

    const handleClear = useCallback(() => {
        setSearch("");
    }, []);

    useEffect(() => {
        if (rawEntities.length === 0) {
            return;
        }
        const filteredEntities = search.trim().length === 0 ? rawEntities : rawEntities.filter((u) => {
            return Object.values(u).some((v) => v.toString().toLowerCase().includes(search.toLowerCase()));
        });
        const newEntities = filteredEntities.slice().sort(entitySorter(order));
        // @ts-ignore
        dispatch(getSetAction(newEntities, name));
    }, [dispatch, order, name, rawEntities, search]);

    if (!entities) {
        return <AdminProgress/>;
    }

    return <AdminBase>
        <Row className="mb-4">
            <Col className="d-flex">
                <Form.Group controlId="formSearch">
                    <Form.Control type="text"
                                  name="search"
                                  value={search}
                                  placeholder="...Search"
                                  onChange={handleChange}
                                  required
                    />
                </Form.Group>
                {search.trim().length > 0 && (
                    <InputGroup>
                        <Button variant="light" onClick={handleClear}>
                            Clear
                        </Button>
                    </InputGroup>
                )}
            </Col>
        </Row>
        <Row>
            {Object.entries(fields).map(([k, v]) => <ColHeader key={k} field={k} label={v}/>)}
        </Row>
        <UserRows entities={entities ?? []}/>
        <Col className="mb-4 mt-4 d-flex flex-column align-items-end justify-content-center">
            <Button variant="primary"
                    size="lg"
                    onClick={() => navigate("/admin/entities/new")}>
                <b>Add New</b>
            </Button>
        </Col>
    </AdminBase>;
}
