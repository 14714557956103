import React from 'react';

import {AlternateRow} from "components/alternate-row";
import {useDelayedFadeIn} from "utils/animation-delay";
import {FadeIn} from "components/fade-in";
import {AspectContainer, Clickable} from "components/styles";
import {GetVisible} from "components/get-visible";
import {ProgressiveImage} from "components/progressive-image";
import {MarkdownRenderer} from "../../utils/markdown-renderer";

type Props = {
    description: string,
    image: string,
    fadeDelay: number,
    isRight: boolean,
    openGallery: () => void,
};

const StaticModuleDetailRow = ({description}: Props) =>
    <AlternateRow content1={<AspectContainer $aspectRatio={1000 / 664}/>}
                  content2={<p>{description}</p>}
                  isRight/>;

const FadedModuleDetailRow = ({description, image, fadeDelay, openGallery, isRight}: Props) => {
    const {nextDelay} = useDelayedFadeIn({start: fadeDelay});

    return <AlternateRow
        content1={
            <Clickable onClick={openGallery}>
                <FadeIn nextDelay={nextDelay}>
                    <ProgressiveImage src={image} aspectRatio={1000 / 664} title={description}/>
                </FadeIn>
            </Clickable>
        }
        content2={
            <FadeIn nextDelay={nextDelay}>
                <MarkdownRenderer markdown={description} />
            </FadeIn>
        } isRight={isRight}/>
};

export const ModuleDetailRow = ({fadeDelay, ...props}: Props) => <GetVisible
    builder={(visible, immediately) => !visible ?
        <StaticModuleDetailRow {...props} fadeDelay={fadeDelay}/> :
        <FadedModuleDetailRow  {...props} fadeDelay={immediately ? fadeDelay : 0}/>
    }/>;
