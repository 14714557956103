import React, {useEffect, useRef, useState} from 'react';
import {animated, useSpring} from "@react-spring/web";
import styled from "styled-components";

type Props = {
    value: number;
    delay?: number;
    $justifyContent?: string;
    formatter?: (value: number) => string;
}

const Wrapper = styled.div<{ $justifyContent: string }>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.$justifyContent};
  overflow: hidden;
  white-space: nowrap;
`

const Box = styled(animated.div)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SlidingNumberFN = ({value, $justifyContent, formatter = (n) => (n ?? 0).toString()}: Props) => {
    const [springs, api] = useSpring(() => ({
        from: {
            y: 0,
            opacity: 1,
        },
    }))

    const [springs2, api2] = useSpring(() => ({
        from: {
            y: -32,
            opacity: 0,
        },
    }))
    const prevValue = useRef<number | null>(null);

    useEffect(() => {
        if (prevValue.current === null || value > prevValue.current) {
            api.start({
                from: {
                    opacity: 0,
                    y: 32,
                },
                to: {
                    opacity: 1,
                    y: 0,
                },
            })
            api2.start({
                from: {
                    opacity: 1,
                    y: 0,
                },
                to: {
                    opacity: 0,
                    y: -32,
                },
            })
        } else {
            api.start({
                from: {
                    opacity: 0,
                    y: -32,
                },
                to: {
                    opacity: 1,
                    y: 0,
                },
            })
            api2.start({
                from: {
                    opacity: 1,
                    y: 0,
                },
                to: {
                    opacity: 0,
                    y: 32,
                },
            })
        }

        prevValue.current = value;
    }, [value, api, api2]); // Dependency array ensures the effect runs when myVar changes

    return (
        <Wrapper $justifyContent={$justifyContent ?? "center"}>
            <Box key={1} style={springs2}>
                {formatter(prevValue.current ?? 0)}
            </Box>
            <Box key={2} style={springs}>
                {formatter(value)}
            </Box>
        </Wrapper>
    );
};

export const SlidingNumber = ({delay = 0, value, ...props}: Props) => {
    const [deferredValue, setDeferredValue] = useState<number>(value);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            // Set the state value after the delay
            setDeferredValue(value);
        }, delay);

        // Clean up the timeout to avoid memory leaks
        return () => clearTimeout(timeoutId);
    }, [value, delay]); // Emp
    return <SlidingNumberFN value={deferredValue} {...props}/>
}
